export default function AnotherCurationItem({ title, description, onClick, imageUrl, emoji }) {
    return <div onClick={() => onClick()}>
        <div className="card card-side rounded-none md:rounded-md bg-base-300 cursor-pointer h-full p-4">
            <div className="card-body p-0 self-center pr-2">
            <h2 className="card-title text-base line-clamp-2">{title}</h2>
            <div className="text-sm line-clamp-1">{description}</div>
            </div>
            {imageUrl && <img className="object-cover rounded-md aspect-square h-16 self-center" src={imageUrl} alt=""/>}
            {!imageUrl && emoji && <div className="h-full"><div className="text-4xl flex items-center h-16">{emoji}</div></div>}
        </div>
    </div>
}
