import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useNavigate } from "react-router-dom";

export default function InviteUserSuccessPage({curationId, curationTitle, username}) {
    const navigate = useNavigate()
    function onBackPressed() {
        navigate(`/collections/${curationId}`, { replace: true })
    }
    return (
        <div>
            <CoorayAppBar title={`Invitation Sent`} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-lg text-base-content text-center pl-4 pr-4"><div>You have invited <span className="font-bold">{username}</span> to <span className="font-bold">{curationTitle}</span>.</div><div>The user needs to login to Roambear and accept the invitation.</div></div>
                <a href={`/collections/${curationId}`} className="mt-4 place-self-center px-4">
                    <button className="btn btn-active btn-primary w-full">Back to {curationTitle}</button>
                </a>
            </div>
        </div>
    )
}
