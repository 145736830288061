import React, { createContext, useState, useContext } from 'react';

const CurationScrollStateContext = createContext();

export const useCurationScrollStateContext = () => {
  return useContext(CurationScrollStateContext);
};

export const CurationScrollStateProvider = ({ children }) => {
  const [curationScrollState, setCurationScrollState] = useState({
    scrollPosition: 0,
    isShowingList: true,
  });

  return (
    <CurationScrollStateContext.Provider value={{ curationScrollState, setCurationScrollState }}>
      {children}
    </CurationScrollStateContext.Provider>
  );
};