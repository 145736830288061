import { useRef, useEffect, useState } from "react";
import { memo } from "react";
import '../../shared/css/mapbox.css'
import "../curation/css/CurationMap.css"
import { MapPopup } from '../../shared/components/map/MapPopup';
import CurationListItem from "../../shared/components/curationitem/CurationListItem";
import MapCurationListItem from "../../shared/components/curationitem/MapCurationListItem";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { logEvent } from "firebase/analytics";
import { useCurationMapContentContext, useCurationMapContext, useCurationMapHoverContext } from "./CurationMapContext";
import { useCurationPlacesContext } from "./CurationPlacesContext";

const MOBILE_SIZE = 768
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = 'pk.eyJ1IjoiamV5ZWUiLCJhIjoiY2xwaHNrcDI0MGJkZzJsbWhpbmgxanhvMyJ9.jscbtyYNweIFs_Bzy998Ow';

export const CurationMap = memo(function CurationMap({ isDesktop, mapMarkerEmoji, analytics, handlePopupItemClick, onToggleBackToListClick, onFilterButtonClick }) {
    const mapRef = useRef()
    const mapCurationItemRef = useRef(null)
    const mapBottomContentRef = useRef()
    const listButtonRef = useRef()
    const [popupContent, setPopupContent] = useState(null)
    const [bottomPopupContent, setBottomPopupContent] = useState(null)
    const [currentMap, setCurrentMap] = useState(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false)
    const {curationMapConfig, setCurationMapConfig} = useCurationMapContext()
    const {curationMapPopup, setCurationMapPopup} = useCurationMapContentContext()
    const {placesData, setPlacesData} = useCurationPlacesContext()
    const {curationHoverId, setCurationHoverId} = useCurationMapHoverContext()
    const [hoverMarker, setHoverMarker] = useState(null)
    const currentMapMarkers = []

    useEffect(() => {
        console.log("map ref is rendered")
        let mapStyle
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            mapStyle = 'mapbox://styles/mapbox/dark-v11'
        } else {
            mapStyle = 'mapbox://styles/mapbox/light-v11'
        }
        const map = new mapboxgl.Map({
            container: 'map-container',
            style: mapStyle,
        });
        map.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            })
        )
    
        map.on('render', function () {
            map.resize();
        });
        map.on('load', function() {
          setCurrentMap(map);
          updateMapMarkers(map, placesData.places)
          setIsMapLoaded(true)
          if (curationMapPopup && curationMapPopup.isBottom) {
            setBottomPopupContent(curationMapPopup)
          } else {
            setPopupContent(curationMapPopup)
          }
        })
        map.on('moveend', () => {
            console.log(`move end`)
            const mapCenter = map.getCenter()
            console.log(`on map drag end ${mapCenter}`);
            setCurationMapConfig(
                {
                    center: mapCenter,
                    zoom: map.getZoom()
                }
            )
        })
        map.on('zoomend', () => {
            const mapCenter = map.getCenter()
            setCurationMapConfig(
                {
                    center: mapCenter,
                    zoom: map.getZoom()
                }
            )
            console.log(`on map zoom end ${mapCenter}`);
        }) 
        
        window.onpopstate = e => {
            console.log(`on pop item ${window.location.href}`)
            if (!window.location.href.includes("#item")) {
                setBottomPopupContent(null);
                setCurationMapPopup(null);
                currentMapMarkers.forEach((marker) => {
                    marker.getElement().style.backgroundColor = 'transparent';
                });
                setPopupContent(null)
            }
        }
    }, [mapRef]);

    useEffect(() => {
        if (isDesktop) {
            const target = placesData.places.find(item => item.id === curationHoverId);
            if (hoverMarker) {
                hoverMarker.remove()
            }
            if (target && currentMap) {
                const el = document.createElement('div');
                el.className = 'marker';
                if (target.emoji) {
                    el.innerHTML = target.emoji;
                } else {
                    el.innerHTML = mapMarkerEmoji;
                }
                el.style.backgroundColor = "rgba(255,255,255,0.5)";
                const marker = new mapboxgl.Marker(el)
                .setLngLat([target.coordinates.longitude, target.coordinates.latitude])
                .addTo(currentMap);
                setHoverMarker(marker)
            }
        }
    }, [curationHoverId])

    useEffect(() => {
        
        if (!isDesktop) {
            const { offsetTop } = mapBottomContentRef.current
            console.log(`bottom popup ${JSON.stringify(bottomPopupContent)}`)  
            if (currentMap && bottomPopupContent) {
                // resetMapBounds(
                //     placesData.places.map((place) => [place.coordinates.longitude, place.coordinates.latitude]),
                //     currentMap,
                //     window.innerHeight - offsetTop,
                // )
                currentMap.easeTo({
                    center: [bottomPopupContent.placeDetails.coordinates.longitude,bottomPopupContent.placeDetails.coordinates.latitude],
                    // zoom: currentMap.getZoom(),
                    padding: {bottom: window.innerHeight - offsetTop + 32},
                    // duration: 500,
                })
            }
        }
    }, [bottomPopupContent])

    function updateMapMarkers(map, placeList) {
        console.log(`updating markers with list size ${placeList.length}`)
        currentMapMarkers.forEach((marker) => {
          marker.remove()
        });
        const geoFeatures = placeList.map((place) => {
            let image
            if (place.images && place.images.length > 0) {
            image = place.images[0].url
            } else {
            image = null
            }
            return {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [place.coordinates.longitude, place.coordinates.latitude]
            },
            'properties': {
                'title': place.title,
                'id': place.id,
                'image': image,
                'description': place.mainComment != null ? place.mainComment.text : null,
                'url': `/places/${place.id}`,
            },
            'placeId': place.id,
            'placeEmoji': place.emoji
            }
        });
        const geojson = {
            'type': 'FeatureCollection',
            'features': geoFeatures
        };


        for (const feature of geojson.features) {
            const el = document.createElement('div');
            el.className = 'marker';
            if (feature.placeEmoji) {
                el.innerHTML = feature.placeEmoji;
            } else {
                el.innerHTML = mapMarkerEmoji;
            }
            if (curationMapPopup && curationMapPopup.placeDetails.id == feature.placeId) {
                el.style.backgroundColor = "rgba(255,255,255,0.7)";
            }
            const marker = new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .addTo(map);
            currentMapMarkers.push(marker);
        }

        if (geoFeatures.length > 0) {
            const coordinates = geojson.features.map((feature) => feature .geometry.coordinates)
            resetMapBounds(coordinates, map, 0)
            if (curationMapConfig) {
                map.easeTo({
                    center: [curationMapConfig.center.lng, curationMapConfig.center.lat],
                    zoom: curationMapConfig.zoom,
                    duration: 0,
                })
            }
        }
        
        map.on("click", (e) => {
            const target = e.originalEvent.target;
            const matchedMarker = currentMapMarkers.find((marker) => target == marker.getElement())
            if (matchedMarker) {
                const markerPlaceDetails = placesData.places[currentMapMarkers.indexOf(matchedMarker)];
                logEvent(analytics, AnalyticsEvent.MAP_MARKER_CLICK, {
                    place_id: markerPlaceDetails.id
                });

                if (isDesktop) {
                    currentMapMarkers.forEach((marker) => {
                        marker.getElement().style.backgroundColor = 'transparent';
                    });
                    matchedMarker.getElement().style.backgroundColor = "rgba(255,255,255,0.7)";
                    setCurationMapPopup(
                        {
                            placeDetails: markerPlaceDetails,
                            coordinates: matchedMarker._lngLat,
                            isBottom: false
                        }
                    )
                    setPopupContent(
                        {
                            placeDetails: markerPlaceDetails,
                            coordinates: matchedMarker._lngLat
                        }
                    );
                } else {
                    currentMapMarkers.forEach((marker) => {
                        if (target == marker.getElement()) {
                            marker.getElement().style.backgroundColor = 'rgba(255,255,255,0.5)';
                        } else {
                            marker.getElement().style.backgroundColor = 'transparent';
                        }
                    });
                    matchedMarker.getElement().style.backgroundColor = "rgba(255,255,255,0.7)";
                    console.log(matchedMarker.getElement())
                    // onMarkerTapOnMobile(markerPlaceDetails)
                    console.log(markerPlaceDetails)
                    setCurationMapPopup(
                        {
                            placeDetails: markerPlaceDetails,
                            coordinates: matchedMarker._lngLat,
                            isBottom: true
                        }
                    )
                    setBottomPopupContent(
                        {
                            placeDetails: markerPlaceDetails
                        }
                    );
                }

                if (!window.location.href.includes("#item")) {
                    window.history.pushState("", "", `#item`);
                }

            } else {
                currentMapMarkers.forEach((marker) => {
                    marker.getElement().style.backgroundColor = 'transparent';
                });
                setBottomPopupContent(null);
                setCurationMapPopup(null);
                if (window.location.href.includes("#item")) {
                    window.history.back();
                }
            }
        });
    }

    function resetMapBounds(coordinates, map, mapBottomExtraPadding) {
        // console.log(`bottom extra padding ${mapBottomExtraPadding} ${JSON.stringify(coordinates)}`)
        var bounds = coordinates.reduce(function(bounds, coord) {
        return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]))
        const padding = {
            top: 50,
            left: 50,
            right: 50,
            bottom: mapBottomExtraPadding + 50
        }

        map.fitBounds(
            bounds, {
                maxZoom: 13,
                padding: padding,
                duration: 0
            }
        )
        console.log(`map center after reset bounds: ${map.getCenter()}`)
        setCurationMapConfig(
            {
                center: map.getCenter(), zoom: map.getZoom()
            }
        )
    }

    return (
        <div className="relative w-full h-full">
        <div ref={mapRef} className={`col-span-1 h-full max-h-screen ${ isMapLoaded ? "" : "blur-lg" }`} id='map-container'></div>
        {popupContent && currentMap && <MapPopup 
            shouldDisableClick={placesData.places.length == 0}
            map={currentMap} 
            placeDetails={popupContent} 
            onPopupClick={handlePopupItemClick}
            analytics={analytics}
            />
        }
        {
            !isDesktop && <div ref={mapBottomContentRef} className={`fixed ${bottomPopupContent ? "bottom-2": "bottom-4"} right-4 left-4 z-10`}>
                <div className='w-full bg-transparent text-right' ref={listButtonRef}>
                <button className="btn md:btn-lg text-base btn-accent normal-case rounded-full mr-1" onClick={() => { onFilterButtonClick() }}>
                <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-accent-content' d="M19 3H5C3.58579 3 2.87868 3 2.43934 3.4122C2 3.8244 2 4.48782 2 5.81466V6.50448C2 7.54232 2 8.06124 2.2596 8.49142C2.5192 8.9216 2.99347 9.18858 3.94202 9.72255L6.85504 11.3624C7.49146 11.7206 7.80967 11.8998 8.03751 12.0976C8.51199 12.5095 8.80408 12.9935 8.93644 13.5872C9 13.8722 9 14.2058 9 14.8729L9 17.5424C9 18.452 9 18.9067 9.25192 19.2613C9.50385 19.6158 9.95128 19.7907 10.8462 20.1406C12.7248 20.875 13.6641 21.2422 14.3321 20.8244C15 20.4066 15 19.4519 15 17.5424V14.8729C15 14.2058 15 13.8722 15.0636 13.5872C15.1959 12.9935 15.488 12.5095 15.9625 12.0976C16.1903 11.8998 16.5085 11.7206 17.145 11.3624L20.058 9.72255C21.0065 9.18858 21.4808 8.9216 21.7404 8.49142C22 8.06124 22 7.54232 22 6.50448V5.81466C22 4.48782 22 3.8244 21.5607 3.4122C21.1213 3 20.4142 3 19 3Z" strokeWidth="1.5"/>
                    </svg> Filter
                </button>
                <button className="btn md:btn-lg text-base btn-primary normal-case rounded-full" onClick={() => { onToggleBackToListClick() }}>
                <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="stroke-primary-content" d="M8 6L21 6.00078M8 12L21 12.0008M8 18L21 18.0007M3 6.5H4V5.5H3V6.5ZM3 12.5H4V11.5H3V12.5ZM3 18.5H4V17.5H3V18.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> List
                </button>
                </div>
                {bottomPopupContent && currentMap && <div className="w-full px-2 py-2">
                    <MapCurationListItem 
                    ref={mapCurationItemRef}
                    imageHeightClass="h-32"
                    placeItem={bottomPopupContent.placeDetails} 
                    clickEvent={
                        (placeItem) => {
                            console.log(`map item clicked ${JSON.stringify(placeItem)}`)
                            handlePopupItemClick(placeItem.id, placeItem)
                        }
                    }
                    analytics={analytics}
                />
                </div>
                }
          </div>
        }
        </div>
    );
    });
