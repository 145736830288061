import FilterChipsContainer from "../FilterChipsContainer"
import { useState, useEffect, useRef } from "react"
import FilterOptionChip from "../filteroptionchip/FilterOptionChip"
import Sheet from 'react-modal-sheet';

export default function FilterModal(
    { isFilterModalOpen, filters, selectedFilterOptions, existingDistance, onApplyClick, onFilterModalDismiss, visitSelections }
) {

    const footerRef = useRef(null)
    const [enabledFilters, setEnabledFilters] = useState(selectedFilterOptions)
    const [isFilteringByDistance, setIsFilteringByDistance] = useState(existingDistance != null)
    const [selectedDistance, setSelectedDistance] = useState(existingDistance)
    const [selectedVisits, setSelectedVisits] = useState(visitSelections)
  
    useEffect( () => {
        setEnabledFilters(selectedFilterOptions)
    }, [selectedFilterOptions])

    useEffect( () => {
        setSelectedVisits(visitSelections)
    }, [visitSelections])

    useEffect( () => {
        console.log(`showing filter modal distance ${existingDistance}`)
        if (existingDistance) {
            setSelectedDistance(existingDistance)
            setIsFilteringByDistance(true)
        }
    }, [existingDistance])

    function onFilterOptionToggled(clickedOption) {
        console.log(`onClick ${clickedOption}`)
        let updatedFilterOptions
        if (enabledFilters.includes(clickedOption.id)) {
          updatedFilterOptions = enabledFilters.filter((filterOptionId) => filterOptionId !== clickedOption.id)
        } else {
          updatedFilterOptions = enabledFilters.concat([clickedOption.id])
        }
        setEnabledFilters(updatedFilterOptions)
    }

    function onFilerByDistanceChange(event) {
        const {checked} = event.target;
        console.log(`ischecked ${checked}`)
        if (checked && selectedDistance == null) {
            setSelectedDistance(100)
        }
        setIsFilteringByDistance(checked)
    }

    function onDistanceChange(event) {
        console.log(`onDistanceChange ${event.target.value}`)
        setSelectedDistance(event.target.value)
    }

    return <Sheet isOpen={isFilterModalOpen} onClose={() => {onFilterModalDismiss()}} onOpenEnd={() => {
    }} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
            <div className="p-0">
        <div className={`px-6`}>
            <div className="h-8"></div>
        {
            filters.map((filter) => {
                return <div className="mb-4">
                    <h3 className="font-bold text-lg">{filter.label}</h3>
                    <div className="pt-1">
                        <FilterChipsContainer 
                            key={filter.id}
                            title={null}
                            filterOptions={filter.filterOptions.map((filterOption) => {
                                filterOption.isSelected = enabledFilters.includes(filterOption.id)
                                return filterOption
                            })}
                            onFilterOptionClick={onFilterOptionToggled}
                            />
                    </div>
                </div>

            })
        }
        <div className="mb-4">
            <h3 className="font-bold text-lg">Visit</h3>
            <div className="pt-1">
                <div direction="row" spacing={1} className='gap-2 max-w-full w-full flex flex-wrap'>
                    {
                        <FilterOptionChip
                            key={0}
                            label={"Visited"}
                            endIcon={
                                selectedVisits.visited ? <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>: null
                            }
                            isSelected={selectedVisits.visited}
                            onFilterOptionClick={() => {
                                const newValue = !selectedVisits.visited
                                setSelectedVisits(
                                    { ...selectedVisits, visited: newValue}
                                )
                            }}
                        />
                    }
                    {
                        <FilterOptionChip 
                            key={0}
                            label={"Not visited"}
                            endIcon={
                                selectedVisits.nonVisited ? <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>: null
                            }
                            isSelected={selectedVisits.nonVisited}
                            onFilterOptionClick={() => {
                                const newValue = !selectedVisits.nonVisited
                                setSelectedVisits(
                                    { ...selectedVisits, nonVisited: newValue}
                                )
                            }}
                        />
                    }
                </div>
            </div>
        </div>
        <div className="mb-4">
            <h3 className="font-bold text-lg">Distance</h3>
            <div className="form-control">
            <label className="label cursor-pointer">
                <span className="label-text text-base">Filter by distance from you</span> 
                <input type="checkbox" className="checkbox" checked={isFilteringByDistance} onChange={onFilerByDistanceChange}/>
            </label>
            </div>
            {
                isFilteringByDistance && <div className="pt-1">
                <input type="range" min="0" max="100" value={selectedDistance} className="range" onChange={onDistanceChange} />
                <span className="label-text text-base">{selectedDistance} km from you</span> 
                </div>
            }
        </div>
        <div className="h-24"></div>
        </div>
        <div className="modal-action absolute bottom-0 right-0 bg-base-100 w-full px-6 py-4" ref={footerRef}>
            <button className="btn" onClick={
                () => {
                    setEnabledFilters([])
                    setIsFilteringByDistance(false)
                    setSelectedDistance(null)
                    onApplyClick(
                        filters.flatMap((filter) => filter.filterOptions).map((option) => {
                            return {
                                ...option, isSelected: false
                            }
                        }),
                        null,
                        {
                            visited: true,
                            nonVisited: true,
                        }
                    )
                    onFilterModalDismiss()
                }
                }>Clear</button>
            <button className="btn btn-success text-success-content mr-2" onClick={
                () => {
                    console.log(`${isFilteringByDistance} ${selectedDistance}`)
                    let appliedDistance = null
                    if (isFilteringByDistance) {
                        appliedDistance = selectedDistance
                    }
                    onApplyClick(
                        filters.flatMap((filter) => filter.filterOptions).map((option) => {
                            option.isSelected = enabledFilters.includes(option.id)
                            return option
                        }),
                        appliedDistance,
                        selectedVisits,
                    )
                    // document.getElementById(modalId).close()
                    onFilterModalDismiss()
                }
                } >Apply
            </button>
            <button className="btn btn-ghost" onClick={
                () => {
                    setEnabledFilters(selectedFilterOptions)
                    // document.getElementById(modalId).close()
                    onFilterModalDismiss()
                }
                }>Close</button>
        </div>
        </div>
        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onFilterModalDismiss()
        }}/>
    </Sheet>
}