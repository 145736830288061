import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import { logEvent } from "firebase/analytics"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"


export default function ImportFromGoogleMapsTutorial({ analytics }) {
    const navigate = useNavigate()

    useEffect(() => {
        logEvent(analytics, AnalyticsEvent.IMPORT_FROM_GOOGLE_MAPS_TUTORIAL_VIEW, {})
    }, [])

    function onBackPressed() {
      navigate(-1)
    }

    return <div>
        <CoorayAppBar 
        title={ "Importing from Google Maps" }
        onBackPressed={onBackPressed}
        navigationType={NavigationButtonType.Close}
        />
        {
          <div className="px-4 gap-y-2 flex flex-col pt-4">
            <div className="text-lg text-base-content">Roambear makes it easy to add a place to a collection straight from the Google Maps app.</div>
            <div className="text-lg text-base-content">Check out the screenshots below for simple instructions on how to do it.</div>
            <div>
                <div className="text-lg text-base-content font-bold pb-2 pt-16">Step #1</div>
                <img className="w-full max-w-md" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-tutorial-resources/import-from-google-maps/add-from-google-maps-1.jpg"/>
            </div>
            <div>
            <div className="text-lg text-base-content font-bold pb-2 pt-16">Step #2</div>
                <img className="w-full max-w-md" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-tutorial-resources/import-from-google-maps/add-from-google-maps-2.jpg"/>
            </div>
            <div>
            <div className="text-lg text-base-content font-bold pb-2 pt-16">Step #3</div>
                <img className="w-full max-w-md" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-tutorial-resources/import-from-google-maps/add-from-google-maps-3.jpg"/>
            </div>
            <div>
            <div className="text-lg text-base-content font-bold pb-2 pt-16">Step #4</div>
                <img className="w-full max-w-md" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-tutorial-resources/import-from-google-maps/add-from-google-maps-4.jpg"/>
            </div>
            <div>
            <div className="text-lg text-base-content font-bold pb-2 pt-16">Step #5</div>
                <img className="w-full max-w-md" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-tutorial-resources/import-from-google-maps/add-from-google-maps-5.jpg"/>
            </div>
          </div>
        }
    </div>
}