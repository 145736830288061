import axios from "axios";
import { getUpdateFilterUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class FilterRepository {
    async updateFilterById(curationId, filterId, filterName, options) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getUpdateFilterUrl(curationId, filterId),
            {
                filter: {
                    filterName: filterName,
                    options: options
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }
}
