import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import { Link, useNavigate } from 'react-router-dom';
import UserRepository from '../../repository/UserRepository';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function RemoveAccountPage( { analytics } ) {

  const [session, setSession] = useState(null)
  const [loadingText, setLoadingText] = useState(null)
  const [isCompleted, setIsCompleted] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    logEvent(analytics, AnalyticsEvent.ENTER_INVITE_CODE_VIEW, {})

    return () => {
      subscription.unsubscribe()
    }
  }, [analytics])


  async function onConfirmDeleteClick() {
    setLoadingText("Sending request ...")
    const userRepository = new UserRepository()
    await userRepository.requestToDeleteAccount()
    setLoadingText(null)
    setIsCompleted(true)
}

  async function logout() {
      setLoadingText("Signing out ...")
      const { error } = await supabase.auth.signOut()
      navigate("/login")
  }

  if (session) {
    return (
        <div className='flex flex-col h-screen'>
          <div className="navbar bg-base-100">
                <div className="flex-1 pl-2">
                <div className="">
                    <Link to="/">
                      <img className="h-8" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources/logos/roambear-full-logo-two-colors.png"/>
                    </Link>
                </div>
                </div>
                <div className="flex-none">
                    <div tabIndex="0" role="button" className="btn btn-ghost" onClick={() => {
                        logout()
                    }}>

                        <div className="indicator">
                        <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="stroke-base-content" d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.2429 22 18.8286 22 16.0002 22H15.0002C12.1718 22 10.7576 22 9.87889 21.1213C9.11051 20.3529 9.01406 19.175 9.00195 17" strokeWidth="1.5" strokeLinecap="round"/>
                        <path className="stroke-base-content" d="M15 12L2 12M2 12L5.5 9M2 12L5.5 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </div>
                        <span className="normal-case text-base-content">Logout</span>
                    </div>
                </div>
          </div>
          <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>
              <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg">Press the button below to send a request to delete your Roambear account. Our team will contact you via email shortly after.</div>
              <div className='self-center pt-4'>
                <button className="btn btn-primary normal-case font-bold group ml-2" onClick={() => { 
                  onConfirmDeleteClick()
                 }}>Request to delete account</button>
              </div>
            
          </div>
          {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{loadingText}</div>
            </div>
            }
            {isCompleted && <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/50'>
                <div className='font-normal text-base-content mt-2'>Request sent. Our team will contact you via email within the next few days!</div>
            </div>
            }
        </div>
    )
  } else {
    return <div className='w-screen h-screen'>
              <div className="navbar bg-base-100 px-4">
            <div className="">
              <Link to="/">
                <img className="h-8" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources/logos/roambear-full-logo-two-colors.png"/>
              </Link>
            </div>
        </div>
        <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden'>
              <div className="pt-16 text-base text-base-content w-full text-center self-center max-w-lg">Please login before proceeeding</div>
              <div className='self-center pt-4'>
                <button className="btn btn-primary normal-case font-bold group ml-2" onClick={() => { 
                  supabase.auth.signInWithOAuth({
                    provider: 'google',
                    options: {
                      redirectTo: `${window.location.protocol + "//" + window.location.host}/profile/delete`,
                    }
                  })
                 }}>Login</button>
              </div>
            
        </div></div>
  }
}