import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import UserRepository from "../../repository/UserRepository"
import { logEvent } from "firebase/analytics"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { Helmet } from "react-helmet"
import { LazyLoadImage } from "react-lazy-load-image-component"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function ProfilePage({analytics}) {

    const [loadingText, setLoadingText] = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const navigate = useNavigate()
    const { userId }= useParams()

    useEffect(() => {
  
        const userRepository = new UserRepository()
        async function getProfile(profileUserId) {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const response = await userRepository.getUserProfileById(profileUserId)
              console.log(response)
              setUserProfile(response.user)
              setLoadingText(null)
            }
        }
        getProfile(userId)

        logEvent(analytics, AnalyticsEvent.PROFILE_VIEW, {
          profile_id: userId
        })
      }, [userId])

    function onBackPressed() {
      navigate(-1)
    }

    return <div>
      <Helmet><title>{userProfile ? userProfile.name : "Profile"} | Roambear</title></Helmet>
        <CoorayAppBar title={ "" } onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
        {
          userProfile && <div className="px-4 gap-y-2 flex flex-col pt-4">
            { !userProfile.profilePicture && <div className="avatar placeholder self-center">
            <div className="bg-neutral text-xl text-neutral-content rounded-full w-24 border-4 border-base-content">
                <span>{ userProfile.name[0] }</span>
            </div>
            </div> 
            }
            { userProfile.profilePicture && <div className="avatar self-center">
              <div className="w-24 border-4 border-base-content rounded-full">
                <LazyLoadImage src={userProfile.profilePicture} />
              </div>
            </div>
            }
            <div className="text-sm font-normal px-4 md:px-12 text-center w-full pt-2">@{userProfile.handler}</div>
            <div className="text-xl font-bold px-4 md:px-12 text-center w-full pt-2">{userProfile.name}</div>
          </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
        <FeedbackButton />
        }
    </div>
}