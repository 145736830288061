import { useEffect } from "react";

const MIN_HEIGHT = 200

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef,
  value
) => {
  useEffect(() => {
    if (textAreaRef) {
      console.log("recalculating text area")
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      let scrollHeight;
      if (textAreaRef.scrollHeight > MIN_HEIGHT) {
        scrollHeight = textAreaRef.scrollHeight;
      } else {
        scrollHeight = MIN_HEIGHT;
      }

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
