import { useRef, useState } from "react";
import useAutosizeTextArea from "../../shared/components/useAutosizeTextArea";

export default function AddCommentModalContent({
    isSavingComment, 
    commentInput, 
    commentImages, 
    onCommentInputChange, 
    onCancelClick,
    onSaveClick,
    onImagesSelected
}) {

    const textAreaRef = useRef();

    useAutosizeTextArea(textAreaRef.current, commentInput);

    function onFileInputChange(event) {
        const imageFiles = []
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i]
            const url = URL.createObjectURL(file)
            imageFiles.push(
                {file: file, url: url}
            )
        }
        onImagesSelected(imageFiles)
    }

    function onThumbnailClick(clickedId) {

    }

    return (
        <div className="overflow-y-scroll pb-40">
            <div className="form-control w-full px-4 py-4">
                <label className="label">
                <span className="label-text text-sm">Comment</span>
                </label>
                <textarea 
                    ref={textAreaRef}
                    value={commentInput}
                    className="textarea textarea-bordered h-32 text-base rounded-md overflow-y-hidden"
                    placeholder="Describe your experience, add a review, ask a question ..."
                    onChange={onCommentInputChange}
                ></textarea>
            </div>
            <input id="upload-comment-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange} multiple/>
            <div className="px-4 flex">
                {
                    commentImages.length > 0 && <div className="flex-none">
                        <button className="btn btn-ghost h-24 w-24 p-0 rounded-md border-base-content border-dashed border-2 " onClick={() => {document.getElementById('upload-comment-image-input').click();}}>
                        <div className="h-20 w-20 flex place-content-center items-center text-2xl font-bold rounded-md ">+</div>
                        </button>
                    </div>
                }
                <div id="place-images-container" className="carousel rounded-md h-24 w-full space-x-2 grow">
                {
                    commentImages.length != 0 && <div className="carousel-item w-2 rounded-md"></div>
                }
                {
                    commentImages.length == 0 && <div className="carousel-item">
                        <button className="btn btn-ghost h-24 w-32 p-0 rounded-md border-base-content border-dashed border-2 normal-case" onClick={() => {document.getElementById('upload-comment-image-input').click();}}>
                        <div className="h-20 w-32 flex place-content-center items-center text-md font-bold rounded-md ">
                        <svg className="h-4 w-4 mr-1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="stroke-base-content group-hover:stroke-base-100" d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg> Add images</div>
                        </button>
                    </div>
                }
                {
                    commentImages.map((image, index) => {
                        return <div className="carousel-item" id={`thumbnail-${index}`} key={`thumbnail-${index}`} onClick={() => onThumbnailClick(index)}>
                            <img className="h-24 w-24 object-cover rounded-md" src={image.url} />
                        </div>
                    })
                }</div>
            </div>
            <div className='fixed bottom-0 p-4 w-full text-center flex items-center justify-center bg-base-100/30 backdrop-blur-md gap-4'>
                <button className="btn btn-success normal-case" onClick={() => onSaveClick() }>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                    </svg>
                    Save
                </button>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Cancel
                </button>
            </div>
            {isSavingComment &&
            <div className='w-full h-screen absolute top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content text-base mt-2'>Saving ...</div>
            </div>
            }
        </div>
    )
    
}