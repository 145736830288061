import React, { createContext, useState, useContext } from 'react';

const CurationMembersContext = createContext();

export const useCurationMembersContext = () => {
  return useContext(CurationMembersContext);
};

export const CurationMembersProvider = ({ children }) => {
  const [curationMemberResponse, setCurationMemberResponse] = useState(null);

  return (
    <CurationMembersContext.Provider value={{ curationMemberResponse, setCurationMemberResponse }}>
      {children}
    </CurationMembersContext.Provider>
  );
};