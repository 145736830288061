import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import UserRepository from "../../repository/UserRepository"
import CurationRepository from "../../repository/CurationRepository"
import AnotherCurationItem from "../placedetails/AnotherCurationItem"
import { logEvent } from "firebase/analytics"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { Helmet } from "react-helmet"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function CurationPicker({
  shouldShowTitleAppBar, analytics, onPickerBackPressed, onPickerCurationSelected
}) {

    const [userCurations, setUserCurations] = useState([])
    const [joinedCurations, setJoinedCurations] = useState([])
    const [loadingText, setLoadingText] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
      async function getUserCurations() {
        setLoadingText("Loading ...")
        const curationRepository = new CurationRepository()
        const {
          data: { user },
        } = await supabase.auth.getUser()
        if (user == null) {
          navigate("/login", {replace: true})
        } else {
          const curationResponse = await curationRepository.getUserCurations()
          console.log(curationResponse)
          setUserCurations(
              curationResponse.ownedCurations.map((curation) => {
                  let placeCount
                  if (curationResponse.curationPlaceCount[curation.id]) {
                      placeCount = curationResponse.curationPlaceCount[curation.id]
                  } else {
                      placeCount = 0
                  }
                  return {
                      ...curation, placeCount: placeCount
                  }
              })
          )

          setJoinedCurations(
              curationResponse.joinedCurations.map((curation) => {
                  let placeCount
                  if (curationResponse.curationPlaceCount[curation.id]) {
                      placeCount = curationResponse.curationPlaceCount[curation.id]
                  } else {
                      placeCount = 0
                  }
                  return {
                      ...curation, placeCount: placeCount
                  }
              })
          )
          setLoadingText(null)
        }
      }

      logEvent(analytics, AnalyticsEvent.CURATION_PICKER_VIEW, {})
      getUserCurations()

    }, [])

    function onBackPressed() {
      onPickerBackPressed()
    }

    function onCurationTap(curationId, curationName) {
      onPickerCurationSelected(curationId, curationName)
    }

    return <div className="w-screen h-screen">
        <Helmet><title>Pick a collection | Roambear</title></Helmet>
        {
          shouldShowTitleAppBar && <CoorayAppBar 
          title={ "Pick a collection" }
          onBackPressed={onBackPressed}
          navigationType={NavigationButtonType.Close}
          />
        }
        <div className="w-full flex place-content-center">
        <div className="w-full flex flex-col bg-base-100 gap-y-2 max-w-lg">
        {
          userCurations.map((curation) => {
              return <div className="px-4">
                <AnotherCurationItem 
                  title={curation.title}
                  description={curation.description}
                  emoji={curation.emoji}
                  imageUrl={curation.coverPhoto}
                  onClick={() => { onCurationTap(curation.id, curation.title) }}
                />
              </div>
          })
        }
        {
          userCurations.length > 0 && <div className="h-16"></div>
        }
        </div>
        </div>

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
    </div>
}