const BASE_URL = "https://oesctluxvegwtcqckcdg.supabase.co/functions/v1/api"

export function getCurationsUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}`
}

export function getDeleteCurationUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}`
}

export function getUserCurationsUrl() {
    return `${BASE_URL}/curations`
}

export function getCreateCurationUrl() {
    return `${BASE_URL}/curations`
}

export function getSearchCurationsUrl(input) {
    return `${BASE_URL}/curations/search?searchInput=${input}`
}

export function getUpdateCurationUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}`
}

export function getUpdateCurationCoverPhotoUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}/cover`
}

export function getPlacesUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}/places`
}

export function getCurationMembersUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}/members`
}

export function getCurationPlaceSuggestionsUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}/suggestions`
}

export function addPlaceSuggestionToCurationUrl(suggestionId, curationId) {
    return `${BASE_URL}/curations/${curationId}/suggestions/${suggestionId}`
}

export function getPlaceDetailsUrl(placeId) {
    return `${BASE_URL}/places/${placeId}`
}

export function getPlacePreferencesUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/preferences`
}

export function saveVisitUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/visit`
}

export function getDeletePlaceUrl(placeId) {
    return `${BASE_URL}/places/${placeId}`
}

export function getPlaceCommentsUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/comments`
}

export function getAddPlaceCommentUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/comments`
}

export function getDeletePlaceCommentUrl(placeId, commentId) {
    return `${BASE_URL}/places/${placeId}/comments/${commentId}`
}

export function getPinPlaceCommentUrl(placeId, commentId) {
    return `${BASE_URL}/places/${placeId}/comments/${commentId}/pin`
}

export function getUnpinPlaceCommentUrl(placeId, commentId) {
    return `${BASE_URL}/places/${placeId}/comments/${commentId}/unpin`
}

export function getAddPlaceUrl() {
    return `${BASE_URL}/places`
}

export function getAddExtractedPlacesUrl() {
    return `${BASE_URL}/places/extracted`
}

export function getEditPlaceUrl(placeId) {
    return `${BASE_URL}/places/${placeId}`
}

export function getCopyPlaceUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/copy`
}

export function getUploadPlaceImageUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/images`
}

export function getFeedbackUrl() {
    return `${BASE_URL}/feedback`
}

export function getGooglePlacesAutocompleteUrl(searchInput) {
    return `${BASE_URL}/google/places/autocomplete?input=${searchInput}&key=AIzaSyCnf17r6a_GIVfD6Px-cMl9KMLaMgclhb4`
}

export function getGooglePlaceIdFromShareUrl() {
    return `${BASE_URL}/google/places/share`
}

export function getExtractProgressUrl(curationId, progressId) {
    return `${BASE_URL}/curations/${curationId}/extract/${progressId}`
}

export function getUpdateFilterUrl(curationId, filterId) {
    return `${BASE_URL}/curations/${curationId}/filters/${filterId}`
}

export function getVerifyInviteCodeUrl() {
    return `${BASE_URL}/code`
}

export function getUserSignUpStatusUrl() {
    return `${BASE_URL}/user/enrollment`
}

export function getUpdateUserProfileUrl() {
    return `${BASE_URL}/user`
}

export function getUpdateUserProfilePictureUrl() {
    return `${BASE_URL}/user/picture`
}

export function getUserProfileUrl() {
    return `${BASE_URL}/user`
}

export function getUserProfileByIdUrl(userId) {
    return `${BASE_URL}/user/${userId}`
}

export function getUpdateUserInterestsUrl() {
    return `${BASE_URL}/user/interests`
}

export function getUserPendingInvitationsUrl() {
    return `${BASE_URL}/user/invitations/pending?rand=${(new Date()).getTime()}`
}

export function getSendInvitationUrl(curationId) {
    return `${BASE_URL}/curations/${curationId}/invite`
}

export function getAcceptInvitationUrl(invitationId) {
    return `${BASE_URL}/user/invitations/${invitationId}/accept`
}

export function getDeclineInvitationUrl(invitationId) {
    return `${BASE_URL}/user/invitations/${invitationId}/decline`
}

export function getDeleteAccountUrl() {
    return `${BASE_URL}/user`
}

export function getUpvotePlaceUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/upvote`
}

export function getDownvotePlaceUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/downvote`
}

export function getRemovePlaceVoteUrl(placeId) {
    return `${BASE_URL}/places/${placeId}/vote`
}