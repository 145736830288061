export default function PendingMemberItem(email, clickEvent) {
    return <div className="px-4 py-4 rounded-md bg-base-200 cursor-pointer hover:bg-base-300 md:grid md:grid-cols-5 md:items-center" onClick={clickEvent}>
        <div className={`md:col-span-5 md:flex md:flex-col md:justify-between md:min-h-full`}>
            <div className="top-section w-full py-2">
                <div className="flex">
                    <div>
                        <div className="grow text-lg font-bold self-center text-base-content line-clamp-2">
                            { email }
                        </div>
                        <div className="grow text-base font-base self-center text-base-content italic">
                            Pending acceptance
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
    </div>
    
}