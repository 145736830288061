import PlaceRepository from "../../repository/PlaceRepository"

export async function getPlaces(supabase, curationId, filterOptionMap, selectedFilterOptions, sortType, coordinates, distance, visits) {
    const placeRepository = new PlaceRepository()
    const {
      data: { user },
    } = await supabase.auth.getUser()
    const places = await placeRepository.getPlaces(curationId, selectedFilterOptions, sortType, coordinates, distance, visits)
    const placeIdToComment = places.commentPerPlaceId
    const placeIdToImages = places.imagesPerPlaceId
    const placeIdToDistance = places.placeToUserLocationDistances
    const placeAuthors = places.placeUsers
    const placeIdToUserVote = places.placeToUserVote
    const highestVotedPlaceId = places.highestVotedPlaceId
    const placeList = places.places.map((place) => {
        let comment
        if (placeIdToComment[place.id]) {
          comment = {
            ...placeIdToComment[place.id], user: places.commentUsers[placeIdToComment[place.id].authorUserId]
          }
        } else {
          comment = null
        }
        let images
        if (placeIdToImages[place.id]) {
          images = placeIdToImages[place.id]
        } else {
          images = []
        }
  
        const filterOptions = place.filterOptionIds.filter((filterOptionId) => filterOptionId in filterOptionMap).map((filterOptionId) => {
          return filterOptionMap[filterOptionId]
        })
        const filterOptionPerFilter = {}
        filterOptions.forEach((filterOption) => {
          if (filterOption.filter.id in filterOptionPerFilter) {
            filterOptionPerFilter[filterOption.filter.id].options.push(filterOption)
          } else {
            filterOptionPerFilter[filterOption.filter.id] = {
              options: [filterOption],
              filter: filterOption.filter
            }
          }
        })
        const placeFilters = Object.keys(filterOptionPerFilter).map((key) => {
          return {
            filterLabel: filterOptionPerFilter[key].filter.label,
            optionsLabel: filterOptionPerFilter[key].options.map((filterOption) => { return filterOption.label }).join(", ")
          }
        })
        let author = null
        if (user.id != place.authorUserId) {
          author = placeAuthors[place.authorUserId]
        }
        return {
          ...place, 
          key: place.id, 
          mainComment: comment,
          images: images,
          filterOptions: placeFilters,
          distance: placeIdToDistance[place.id],
          author: author,
          userVote: placeIdToUserVote[place.id],
          isTopVote: highestVotedPlaceId == place.id
        }
    })
    return placeList
  }

  export function getUserLocation() {
    return new Promise(resolve => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(`Position obtained ${position.coords.latitude} ${position.coords.longitude}`);
            resolve(position.coords)
          },
          () => {
            resolve(null)
          })
      } else {
        console.log("Geolocation not supported");
        resolve(null)
      }
    });
  }