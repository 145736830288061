import HomeCurationItem from "../../shared/components/curation/HomeCurationItem";
import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CurationRepository from "../../repository/CurationRepository";
import PendingInvitations from "../../shared/components/pendinginvitations/PendingInvitations";
import UserRepository from "../../repository/UserRepository";
import AutocompleteInput from "../../shared/components/AutocompleteInput";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function ExplorePage() {

    const [loadingText, setLoadingText] = useState(null)
    const [userCurations, setUserCurations] = useState([])
    const [pendingInvitations, setPendingInvitations] = useState([])
    const [isInvitationSectionSaving, setIsInvitationSectionSaving] = useState(false)
    const navigate = useNavigate()

    const [searchTerm, setSearchTerm] = useState('')
    const [searchPredictions, setSearchPredictions] = useState([])
    const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false)

    useEffect(() => {
      const curationRepository = new CurationRepository()
      async function getCurationsAutocomplete(input) {
        const curationReponse = await curationRepository.getSearchCurations(input)
        console.log(curationReponse)
        setSearchPredictions(
            curationReponse.curations.map((curation) => {
            return {
              label: curation.title,
              secondaryLabel: curation.description,
              key: curation.id,
              link: `/collections/${curation.id}`
            }
          })
        )
        setIsAutocompleteLoading(false)
      }
  
      const delayDebounceFn = setTimeout(() => {
        console.log(searchTerm)
        if (searchTerm) {
          getCurationsAutocomplete(searchTerm)
        }
      }, 1500)
  
      return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    function handlePlaceInputChange(event) {
      setIsAutocompleteLoading(event.target.value != "")
      setSearchPredictions([])
      setSearchTerm(event.target.value)
    }

    function handlePlaceItemSelected(item) {
        console.log(item)
        setSearchPredictions([])
        navigate(`/collections/${item.key}`)
    }

    useEffect(() => {
        async function getPendingInvitations() {
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user != null) {
                const userRepository = new UserRepository()
                const pendingInvitationResponse = await userRepository.getUserPendingInvitations()
                console.log(`${JSON.stringify(pendingInvitationResponse)}`)
                setPendingInvitations(
                    pendingInvitationResponse.invitations
                )
            }
        }
        getPendingInvitations()
  
      }, [])



    async function saveAcceptInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.acceptInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setPendingInvitations(
            pendingInvitations.map((invitation) => {
                if (invitation.id == targetInvitation.id) {
                    return {
                        ...invitation, isAccepted: true
                    }
                } else {
                    return invitation
                }
            })
        )
    }

    async function saveDeclineInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.declineInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }

    function onDismissInvitationClick(targetInvitation) {
        console.log(`dismiss invitation ${JSON.stringify(targetInvitation)}`)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }
    function onAcceptInvitationClick(targetInvitation) {
        saveAcceptInvitation(targetInvitation)
    }

    function onDeclineInvitationClick(targetInvitation) {
        saveDeclineInvitation(targetInvitation)
    }

    return (
        <div className="bg-base-100">
            {/* <div className="text-xl font-bold px-4 pt-4">🌏 Explore</div> */}
            <div>
                <PendingInvitations
                    pendingInvitations={pendingInvitations}
                    onDismissInvitationClick={onDismissInvitationClick}
                    onAcceptInvitationClick={onAcceptInvitationClick}
                    onDeclineInvitationClick={onDeclineInvitationClick}
                    isSaving={isInvitationSectionSaving}
                />
            </div>
            <div className="hero">
            <div className="hero-content text-center text-base-content py-24">
                <div className="max-w-lg">
                <h1 className="mb-5 text-5xl font-bold">❤️ ✅ 🤝</h1>
                <h1 className="mb-5 text-5xl font-bold">Find the list of best _____ in your city</h1>
                <p className="mb-5">Cooray is a platform for everyone to discover and collaborate on a list of best spots around a theme</p>
                {/* <div className="form-control w-full px-4 relative">
                    <svg className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-8 fill-none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="stroke-neutral" d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <input type="text" placeholder="e.g. Best cafes in Singapore ..." className="input input-lg placeholder-neutral/30 input-bordered w-full rounded-md pl-16" onChange={handlePlaceInputChange} value={searchTerm}/>
                </div> */}
                <AutocompleteInput 
                    placeholder={"e.g. Best cafes in Singapore ..."}
                    onValueChange={handlePlaceInputChange}
                    items={ searchPredictions }
                    value={ searchTerm }
                    onItemSelected={handlePlaceItemSelected}
                    isLoading={isAutocompleteLoading}
                    isBig={true}
                    />
                </div>
            </div>
            </div>

            <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:px-4 pt-4 pb-20 items-stretch">
                {
                    userCurations.map((curation) => {
                        const emojiText = curation.emoji != null ? `${curation.emoji} ` : ""
                        return <div className="col-span-3">
                            <HomeCurationItem title={`${emojiText}${curation.title}`} 
                            url={`/collections/${curation.id}`}
                            description={curation.description}
                            numberOfPlaces={curation.placeCount}
                            numberOfContributors={5}
                            imageUrl={curation.imageUrl} 
                            emoji={curation.emoji}
                            />
                        </div>
                    })
                }
            </div>
            {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{loadingText}</div>
            </div>
            }
        </div>
    )
}
