import { useRef, useState } from "react";
import useAutosizeTextArea from "../useAutosizeTextArea";

export default function AddFeedbackModalContent({
    onCancelClick,
    onSaveClick,
    isSavingFeedback
}) {

    const textAreaRef = useRef();
    const [feedbackInput, setFeedbackInput] = useState("")

    useAutosizeTextArea(textAreaRef.current, feedbackInput);

    function onFeedbackInputChange(event) {
        setFeedbackInput(event.target.value)
    }

    return (
        <div className="overflow-y-scroll pb-40">
            <div className="form-control w-full px-4 py-4">
                <label className="label">
                    <div className="flex w-full pb-4">
                    <div className="text-3xl content-center pr-4">👋🏼</div>
                    <div className="label-text text-md content-center">Please share any feedback you have on how we can improve the app, or any issues you encounter!</div>
                    </div>
                </label>
                <textarea 
                    ref={textAreaRef}
                    value={feedbackInput}
                    className="textarea textarea-bordered h-32 text-base rounded-md overflow-y-hidden"
                    placeholder="Your feedback here ..."
                    onChange={onFeedbackInputChange}
                ></textarea>
            </div>
            <div className='fixed bottom-0 p-4 w-full text-center flex items-center justify-center bg-base-100/30 backdrop-blur-md gap-4'>
                <button className="btn btn-success normal-case" onClick={() => onSaveClick(feedbackInput) }>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                    </svg>
                    Save
                </button>
                <button className="btn btn-neutral normal-case" onClick={ () => onCancelClick() }>
                    Cancel
                </button>
            </div>
            {isSavingFeedback &&
            <div className='w-full h-screen absolute top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content text-base mt-2'>Saving your valuable feedback ...</div>
            </div>
            }
        </div>
    )
    
}