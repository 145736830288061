import { LazyLoadImage } from "react-lazy-load-image-component";
import GoogleReviewItem from "../googlereview/GoogleReviewItem";

export default function PlaceSuggestionItem({ item, onAddSuggestionToCollectionClick }) {
    const description = item.description

    return <div className="p-4 cursor-pointer mb-2 md:grid md:grid-cols-5 md:items-center w-full rounded-box bg-base-300">
        {
            item.images && item.images.length > 0 && 
            <div className="rounded-lg h-40 w-full col-span-5">
                <LazyLoadImage className="rounded-md h-full w-full object-cover" src={item.images[0].url} />
            </div>
        }
        <div className={`md:col-span-5 md:flex md:flex-col md:justify-between md:min-h-full`}>
            <div className="top-section py-2">
            <div className="flex grow justify-between pt-1">
                <div className="text-base-content text-base font-bold text-base-content">{ item.title }</div>
                {
                    item.googleReviewScore && <div className="pl-4">
                        <GoogleReviewItem reviewScore={item.googleReviewScore} backgroundCss={"bg-base-100"}/>
                    </div>
                }
            </div>
            {
                description && <div className="text-sm self-center text-base-content line-clamp-2 pt-1">
                    { description }
                </div>
            }
            <button className="btn btn-sm btn-neutral mt-4" onClick={() => {
                onAddSuggestionToCollectionClick(item.id, item.title)
            }}>+ Add to Collection</button>
            </div>
        </div>
    </div>;
}
