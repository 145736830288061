import { logEvent } from "firebase/analytics";
import GoogleNavigateItem from "../googlenavigate/GoogleNavigateItem";
import GoogleReviewItem from "../googlereview/GoogleReviewItem";
import MainComment from "./MainComment";
import React, { useImperativeHandle, useRef } from "react";
import { AnalyticsEvent } from "../../analytics/AnalyticsEvent";

const MapCurationListItem = React.forwardRef(({ placeItem, clickEvent, imageHeightClass, analytics }, ref) => {
    const itemRef = useRef(null)
    const description = placeItem.shortDescription
    useImperativeHandle(ref, () => ({
        // Expose any properties or functions you want to use externally
        getHeight: () => itemRef.current.clientHeight,
    }));

    const chips = placeItem.filterOptions.map((option) => {
        return <div className="center relative inline-block select-none whitespace-wrap rounded-lg bg-base-200 py-2 px-3.5 align-baseline text-sm md:text-base leading-none text-base-content">{ option.filterLabel }: <span className="font-bold">{option.optionsLabel}</span></div>
    })
    const images = placeItem.images.slice(0,1).map((image) => {
        return <div className="carousel-item h-full w-full max-w-full">
            <img className="h-full w-full object-cover max-w-full" src={image.url} />
        </div>
    })
    return <div className="card bg-base-100 shadow-xl cursor-pointer rounded-md" ref={itemRef} onClick={() => {clickEvent(placeItem)}}>
    { images.length > 0 && <figure className="w-full">
        { 
            <div className={`carousel carousel-center w-full ${imageHeightClass}`}>{images}</div>
        }
    </figure>
    }
    <div className={`card-body ${images.length > 0 ? "w-full" : ""} p-4`}>
        <div className="">
            <div className="text-base-content text-sm md:text-base font-bold text-base-content">{ placeItem.title }</div>
            {
                description && <div className="grow text-xs md:text-sm self-center text-base-content line-clamp-1 pt-2">
                    { description }
                </div>
            }
            {
                placeItem.googlePlace && <div className="pt-2">
                    <GoogleNavigateItem reviewScore={placeItem.googlePlace.reviewScore} isSmall={true}  onClick = {(e) => { 
                        logEvent(analytics, AnalyticsEvent.MAP_MARKER_GOOGLE_NAVIGATE_CLICK, {
                            place_id: placeItem.id,
                            reviewScore: placeItem.googlePlace.reviewScore
                        })
                        window.open(placeItem.googlePlace.mapsLink, "_blank"); e.stopPropagation();
                    }}/>
                </div>
            }
        </div>
    </div>
  </div>;

})

export default MapCurationListItem