import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { Helmet } from "react-helmet";
import AddedPlaceItem from "../../shared/components/addplace/AddedPlaceItem";
import FeedbackButton from "../../shared/components/feedback/FeedbackButton";

export default function AddPlaceSuccessPage({analytics,addedPlaces, curationData}) {
    const navigate = useNavigate()

    useEffect(() => {
        logEvent(analytics, AnalyticsEvent.ADD_PLACE_SUCCESS_VIEW, {
            place_id: addedPlaces.map((place) => place.id).join()
        })
    
      }, [])

    function onBackPressed() {
        navigate(`/collections/${curationData.curation.id}`, {replace: true})
    }
    function onAddedPlaceClick(itemId) {
        navigate(`/places/${itemId}`, {replace: true})
    }


    return (
        <div className="">
            <Helmet><title>Added {addedPlaces.length == 1 ? addedPlaces[0].title : `${addedPlaces.length} places`} to {curationData.curation.title}</title></Helmet>
            <CoorayAppBar title={"Added Succesfully"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center justify-center px-4">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-lg text-base-content text-center pl-4 pr-4">Good job! You have added <span className="font-bold">{addedPlaces.length == 1 ? addedPlaces[0].title : `${addedPlaces.length} places`}</span> to <span className="font-bold">{curationData.curation.title}</span></div>
            </div>
            <div className="flex w-full justify-center">
                <div className="w-full grid grid-cols-3 gap-x-4 gap-y-4 pl-4 pr-4 pt-8 md:max-w-xl">
                    <a href={`/collections/${curationData.curation.id}`} className="col-span-3 w-full place-self-end">
                        <button className="btn btn-active btn-primary w-full">
                        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-primary-content" fillRule="evenodd" clipRule="evenodd" d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6ZM2.25 11C2.25 10.5858 2.58579 10.25 3 10.25H9C9.41421 10.25 9.75 10.5858 9.75 11C9.75 11.4142 9.41421 11.75 9 11.75H3C2.58579 11.75 2.25 11.4142 2.25 11ZM2.25 16C2.25 15.5858 2.58579 15.25 3 15.25H10C10.4142 15.25 10.75 15.5858 10.75 16C10.75 16.4142 10.4142 16.75 10 16.75H3C2.58579 16.75 2.25 16.4142 2.25 16Z"/>
                            <path className="fill-primary-content" d="M13 11.7148C13 13.4673 15.1633 15.3304 16.4901 16.3082C16.9442 16.643 17.1713 16.8103 17.5 16.8103C17.8287 16.8103 18.0558 16.643 18.5099 16.3082C19.8367 15.3304 22 13.4674 22 11.7147C22 9.03758 19.5249 8.03806 17.5 10.1061C15.4751 8.03806 13 9.03758 13 11.7148Z"/>
                        </svg>
                        View {curationData.curation.title}
                        </button>
                    </a>
                    <a href={`/collections/${curationData.curation.id}/add`} className="col-span-3 w-full place-self-start">
                        <button className="btn btn-active btn-secondary w-full place-self-start">
                        <svg className="w-5 h-5 fill-secondary-content" viewBox="0 0 512 512"><path d="M411.564,64.437L411.564,64.437c-85.915-85.916-225.212-85.916-311.127,0l0,0c-85.915,85.916-85.915,225.213,0,311.128  l122.907,122.908C232.004,507.134,243.751,512,256,512c12.249,0,23.996-4.866,32.657-13.527l122.907-122.908  C497.479,289.65,497.479,150.353,411.564,64.437z M256,300c-44.183,0-80-35.817-80-80s35.817-80,80-80s80,35.817,80,80  S300.183,300,256,300z"/></svg>
                            Add another place
                        </button>
                    </a>
                </div>
            </div>
            <div className="text-lg text-base-content text-center w-full pt-8 font-bold">Added places</div>
            <div className="w-full pt-4 flex justify-center">
                <div className="w-full md:hidden">
                {
                        addedPlaces.length > 0 && <div className="carousel carousel-center rounded-box space-x-4 px-4 pb-8 w-full">
                            {
                            addedPlaces.map((place) => {
                                return <div className="carousel-item w-11/12 md:w-5/12">
                                <AddedPlaceItem 
                                    item={place}
                                    onItemClick={(event) => {
                                    }}
                                />
                                </div>
                            })
                            }
                        </div>
                    }
                </div>
                <div className="w-full hidden md:block md:max-w-xl">
                {
                        addedPlaces.length > 0 && <div className="px-4 pb-8 w-full">
                            {
                            addedPlaces.map((place) => {
                                return <div className="w-full">
                                <AddedPlaceItem 
                                    item={place}
                                    onItemClick={(event) => {
                                        console.log(`on item click ${place.id}`)
                                        onAddedPlaceClick(place.id)
                                    }}
                                />
                                </div>
                            })
                            }
                        </div>
                    }
                </div>
            </div>
            {
            <FeedbackButton />
            }
        </div>
    )
}
