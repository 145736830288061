import FilterOptionChip from "./filteroptionchip/FilterOptionChip";

export default function FilterChipsContainer({ modifier, title, filterOptions, onFilterOptionClick }) {
    return (
        <div className={modifier}>
            { title && <div className="max-w-full pb-3 text-sm md:text-base font-bold">{title}</div> }
            <div direction={"row"} spacing={1} className='gap-2 max-w-full w-full flex flex-wrap'>
                {
                    filterOptions.map((filterOption) => {
                        let endIcon
                        if (filterOption.isSelected) {
                            endIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        } else {
                            endIcon = null
                        }
                        return <FilterOptionChip 
                            key={filterOption.id}
                            label={filterOption.label}
                            endIcon={endIcon}
                            isSelected={filterOption.isSelected}
                            onFilterOptionClick={() => onFilterOptionClick(filterOption)}
                        />
                        })
                }
            </div>
        </div>
    );
  }
