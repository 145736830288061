import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AppFeatureIntroItem({ title, description, image, isUsingDarkMode }) {
    const maskImageStyle = {
        WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%)'
    };
    return <div className="card card-side rounded-lg bg-base-100 h-full overflow-x-hidden">
            <div className="card-body p-0 h-full flex">
            <div>
            <h2 className="card-title font-bold text-base md:text-xl px-6 py-6">{title}</h2>
            <div className="text-sm md:text-base px-6">{description}</div>
            </div>
            <div className="w-full pl-16 pt-16">
            {image && <LazyLoadImage className="rounded-tl-lg w-full" style={maskImageStyle} src={isUsingDarkMode? image.dark : image.light} alt=""/> }
            </div>
            </div>
        </div>
}
