import { createClient } from "@supabase/supabase-js"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function PendingInvitations({pendingInvitations, onDismissInvitationClick, onAcceptInvitationClick, onDeclineInvitationClick, isSaving}) {
    return (
      pendingInvitations.length > 0 && <div className="text-base-content text-xs flex flex-nowrap flex-col py-4 bg-base-200 mt-4 md:mx-4 rounded-md relative">
        <div className="px-4 text-base font-bold w-full">You have {pendingInvitations.length} pending invitation{ pendingInvitations.length > 1? "s": "" }:</div>
        {
          pendingInvitations.map((invitation) => {
            return (
              <div className="pt-4 flex w-full place-items-center" key={invitation.id}>
                  <div className="grow">
                      <div className="text-sm md:text-base px-4">
                      <span className="inline-flex items-baseline">
                        {
                          invitation.inviter.avatar && <img src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=80" alt="" className="self-center w-5 h-5 rounded-full mr-1" />
                        }
                        {
                          !invitation.inviter.avatar && <div className="self-center avatar placeholder items-baseline mr-1">
                          <div className="bg-neutral text-neutral-content rounded-full h-5 w-5">
                            <span className="text-xs">{invitation.inviter.displayName.toUpperCase().charAt(0)}</span>
                          </div>
                        </div> 
                        }
                        <span className="font-bold">{invitation.inviter.displayName}</span>
                      </span> invites you to join <span className="inline-flex items-baseline font-bold">{ invitation.curation.title }</span>
                      </div>
                      {
                        !invitation.isAccepted && (
                          <div className="w-full px-4 pt-2 flex gap-x-2">
                            <button className="btn btn-sm btn-success" onClick={() => { onAcceptInvitationClick(invitation) }}>Accept</button>
                            <button className="btn btn-sm btn-error" onClick={() => { onDeclineInvitationClick(invitation) }}>Decline</button>
                          </div>
                        )
                      }
                      {
                        invitation.isAccepted && (
                          <div className="w-full px-4 pt-2 flex gap-x-2">
                            <div className="flex place-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 p-1 rounded-full bg-success text-success-content" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                              <span className="text-base pl-2">Accepted</span>
                            </div>
                            <button className="btn btn-sm btn-ghost" onClick={() => { onDismissInvitationClick(invitation) }}>Dismiss</button>
                          </div>
                        )
                      }
                  </div>
              </div>
            )
          })
        }

      {isSaving &&
      <div className='w-full h-full absolute top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
          <div className="loading loading-infinity loading-md"></div>
          <div className='font-normal text-base-content mt-2 text-base'>Saving ...</div>
      </div>
      }
      </div>
    )
}