import { useEffect, useState } from "react"
import AutocompleteInput from "../../shared/components/AutocompleteInput"
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FilterChipsContainer from "../../shared/components/FilterChipsContainer"
import GoogleRepository from "../../repository/GoogleRepository"
import AnotherCurationItem from "../placedetails/AnotherCurationItem"
import Sheet from 'react-modal-sheet';
import { isValidHttpUrl } from "../../shared/utils/StringUtils"
import ExtractPlacesModalContent from "./ExtractPlacesModalContent"
import ExtractedPlaceItem from "../../shared/components/addplace/ExtractedPlaceItem"
import AddingExtractedPlaceItem from "../../shared/components/addplace/AddingExtractedPlaceItem"
import PlaceRepository from "../../repository/PlaceRepository"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"

export default function InputPlacePage({curationData, placeData, onSaveClicked, onFilterOptionClick, onInputChange, onBackPressed, onSelectCurationTap}) {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchUrl, setSearchUrl] = useState('')
    const [searchPredictions, setSearchPredictions] = useState([])
    const [selectedPlace, setSelectedPlace] = useState(placeData)
    const [extractedPlaces, setExtractedPlaces] = useState([])
    const [selectedExtractedPlaces, setSelectedExtractedPlaces] = useState([])
    const [appliedExtractedPlaces, setAppliedExtractedPlaces] = useState([])
    const [isExtractedPlacesBottomSheetShown, setIsExtractedPlacesBottomSheetShown] = useState(false)
    const [isLoadingExtractedPlaces, setIsLoadingExtractedPlaces] = useState(false)
    const [urlInput, setUrlInput] = useState("")
    const [isLoadingPlaceIdFromShareUrl, setIsLoadingPlaceIdFromShareUrl] = useState(false)
    const [autocompleteLoadingText, setAutocompleteLoadingText] = useState(null)
    const [currentUrlDecodeCountDown, setCurrentUrlDecodeCountDown] = useState(null)
    const [currentPlaceLoadCountDown, setCurrentPlaceLoadCountDown] = useState(null)

    useEffect(() => {
      const googleRepository = new GoogleRepository()
      async function getPlacesAutocomplete(input) {
        const places = await googleRepository.getPlacesAutocomplete(input)
        console.log(places)
        setSearchPredictions(
          places.predictions.map((prediction) => {
            return {
              label: prediction.structured_formatting.main_text,
              secondaryLabel: prediction.structured_formatting.secondary_text,
              key: prediction.place_id,
              link: "#"
            }
          })
        )
        setAutocompleteLoadingText(null)
      }
  
      const delayDebounceFn = setTimeout(() => {
        console.log(searchTerm)
        if (searchTerm) {
          getPlacesAutocomplete(searchTerm)
        }
      }, 1500)
  
      return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    useEffect(() => {

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      async function delayAndGetProgress(progressId) {
        await delay(2000)
        const placeRepository = new PlaceRepository()
        const response = await placeRepository.getExtractProgress(curationData.curation.id, progressId)
        console.log(response)
        setExtractedPlaces(response.places)
        setIsLoadingExtractedPlaces(!response.isProcessCompleted)
        if (!response.isProcessCompleted) {
          delayAndGetProgress(progressId)
        } else {
          if (response.decodedPlaces.length > 0) {
            setExtractedPlaces([])
            setSelectedExtractedPlaces([])
            setAppliedExtractedPlaces([])
            setIsExtractedPlacesBottomSheetShown(false)
            const firstPlace = response.decodedPlaces[0]
            handlePlaceItemSelected(
              {
                label: firstPlace.title,
                secondaryLabel: firstPlace.googlePlace.fullAddress,
                key: firstPlace.googlePlace.placeId,
                link: "#"
              }
            )
          }
          setAutocompleteLoadingText(null)
        }
      }

      async function decodePlace(input) {
        const googleRepository = new GoogleRepository()
        try {
          const response = await googleRepository.getPlaceIdFromShareUrl(input)
          console.log(response)
          if (response.decodedPlaces.length > 0) {
            setExtractedPlaces([])
            setSelectedExtractedPlaces([])
            setAppliedExtractedPlaces([])
            const firstPlace = response.decodedPlaces[0]
            handlePlaceItemSelected(
              {
                label: firstPlace.title,
                secondaryLabel: firstPlace.googlePlace.fullAddress,
                key: firstPlace.googlePlace.placeId,
                link: "#"
              }
            )
            setAutocompleteLoadingText(null)
          } else if(response.places.length > 0) {
            setSelectedExtractedPlaces([])
            setAppliedExtractedPlaces([])
            setExtractedPlaces(response.places)
          }

          if (response.isProcessCompleted) {
            setIsLoadingExtractedPlaces(false)
            setAutocompleteLoadingText(null)
            if (currentUrlDecodeCountDown) {
              clearTimeout(currentUrlDecodeCountDown)
              setCurrentUrlDecodeCountDown(null)
            }
            if (currentPlaceLoadCountDown) {
              clearTimeout(currentPlaceLoadCountDown)
              setCurrentPlaceLoadCountDown(null)
            }
          } else {
            delayAndGetProgress(response.progressId)
          }
        } catch(e) {
          setAutocompleteLoadingText(null)
        }
      }
  
      const delayDebounceFn = setTimeout(() => {
        console.log(searchUrl)
        if (searchUrl) {
          setIsExtractedPlacesBottomSheetShown(true)
          decodePlace(searchUrl)
        }
      }, 2500)
  
      return () => clearTimeout(delayDebounceFn)
    }, [searchUrl])

    function startUrlDecodeCountDown() {
      return setTimeout(() => {
        const choices = [
          "This webpage is a novel, we're flipping the pages...",
          "Taking a scenic route through this webpage, hold tight!",
          "Wow, this webpage has a lot to say. Almost there...",
          "This webpage is a marathon, not a sprint. Hang on!",
          "Just a sec, this webpage is writing its memoir...",
          "This webpage is a bit of a chatterbox. Patience, please...",
          "Hold tight, this webpage is telling a long story...",
          "Grabbing popcorn for this epic webpage. Almost done...",
          "Webpage is quite the storyteller, still reading...",
          "Deep diving into this webpage’s content, almost there..."
        ]
        setAutocompleteLoadingText(choices[Math.floor(Math.random() * 10)])
        setCurrentPlaceLoadCountDown(startPlaceLookupCountDown())
      }, 3000)
    }

    function startPlaceLookupCountDown() {
      return setTimeout(() => {
        const choices = [
          "Scouring the digital map for the whereabouts...",
          "Hunting down the exact coordinates...",
          "Navigating the labyrinth of location data...",
          "Seeking the hidden path to the destination...",
          "Tracking down the elusive location like a digital detective...",
          "Searching high and low for the pinpoint...",
          "Unraveling the geographical mystery...",
          "Pinging satellites for precise positioning...",
          "Charting a course through the digital wilderness...",
          "Piecing together the puzzle of its exact whereabouts...",
          "Zeroing in on the exact spot...",
          "Deciphering the location like a digital Sherlock Holmes...",
          "Mapping out the coordinates in the digital realm...",
          "Pinpointing the exact latitude and longitude...",
          "Tracing the breadcrumbs to the location...",
          "Chasing the digital trail to its destination...",
          "Unraveling the mystery of its whereabouts...",
          "Following the virtual treasure map to the spot...",
          "Locating the precise address in the digital ether..."
        ]
        setAutocompleteLoadingText(choices[Math.floor(Math.random() * 19)])
      }, 3000)
    }

    function handlePlaceInputChange(event) {
      setSelectedPlace(null)
      setSearchPredictions([])
      const searchString = event.target.value
      console.log(`searchString is ${searchString}`)
      if (searchString) {
        if (isValidHttpUrl(searchString)) {
          // setAutocompleteLoadingText("Decoding URL ...")
          setIsLoadingExtractedPlaces(true)
          setIsExtractedPlacesBottomSheetShown(true)
          setSearchTerm(null)
          setSearchUrl(searchString)
          // setCurrentUrlDecodeCountDown(startUrlDecodeCountDown())
        } else {
          setAutocompleteLoadingText("Searching ...")
          setSearchUrl(null)
          setSearchTerm(searchString)
        }
      } else {
        setSearchTerm(null)
      }
      onInputChange(searchString)
    }

    function handlePlaceItemSelected(item) {
      console.log(`handle selected ${item}`)
      console.log(item)
      setSelectedPlace(item)
      setSearchPredictions([])

      setExtractedPlaces([])
      setSelectedExtractedPlaces([])
      setAppliedExtractedPlaces([])
    }

    async function onDecodeMapsUrlSaveClick() {
      console.log(`url input ${urlInput}`)
      if (urlInput) {
        setIsLoadingPlaceIdFromShareUrl(true)
        const googleRepository = new GoogleRepository()
        const response = await googleRepository.getPlaceIdFromShareUrl(urlInput)
        console.log(response)
        setIsLoadingPlaceIdFromShareUrl(false)
        if (response.places.length > 0) {
          const firstPlace = response.places[0]
          handlePlaceItemSelected(
            {
              label: firstPlace.title,
              secondaryLabel: firstPlace.googlePlace.fullAddress,
              key: firstPlace.googlePlace.placeId,
              link: "#"
            }
          )
        }
      }
    }

    function onExtractedItemCheckChange(index, isSelected) {
      console.log(`is ${index} ${isSelected}`)
      if (!isSelected) {
        setSelectedExtractedPlaces(
          selectedExtractedPlaces.filter((selected) => selected != index)
        )
      } else if (!selectedExtractedPlaces.includes(index)) {
        const updated = selectedExtractedPlaces.concat([index])
        setSelectedExtractedPlaces(updated)
      }
    }

    function onExtractedItemClick(index, currentSelectState) {
      if (currentSelectState) {
        setSelectedExtractedPlaces(
          selectedExtractedPlaces.filter((selected) => selected != index)
        )
      } else {
        const updated = selectedExtractedPlaces.concat([index])
        setSelectedExtractedPlaces(updated)
      }
    }

    function onDeleteExtractedPlace(index) {
      console.log(`on delete ${index}`)
      setAppliedExtractedPlaces(appliedExtractedPlaces.filter((item) => item != index))
    }

    return (
        <div>
        <CoorayAppBar title={"Add Place"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
        <div className="bg-base-200 px-4 py-4">
            <div className="max-w-full text-base-content text-base">{`Add a place to ${curationData.curation.title}`}</div>
        </div>
        <div className="form-control w-full px-4 pt-4">
          <label className="label">
            <span className="label-text font-bold text-sm">Search for a place</span>
          </label>
          <AutocompleteInput 
              placeholder={"Search something, or paste any link here ..."}
              onValueChange={handlePlaceInputChange}
              items={ searchPredictions }
              value={ selectedPlace?.label ?? searchTerm }
              onItemSelected={handlePlaceItemSelected}
              loadingText={autocompleteLoadingText}
              />
        </div>
        {/* <div className=" w-full pb-8 px-4 pt-2 flex items-center"><span className="font-normal text-sm text-base-content opacity-75"> or </span>
        <button className="btn btn-neutral normal-case font-bold group ml-2" onClick={() => { setIsShowingMapsUrlBottomSheet(true) }}>
        <svg className="fill-neutral-content h-4 w-4" viewBox="0 0 216.975 216.975">
        <g>
          <path d="M182.442,0H34.53C15.49,0,0,15.491,0,34.531v147.913c0,19.04,15.49,34.53,34.53,34.53h147.912
            c19.041,0,34.532-15.49,34.532-34.53V34.531C216.975,15.491,201.483,0,182.442,0z M135.731,48.122
            C135.731,29.858,150.59,15,168.852,15c18.264,0,33.122,14.858,33.122,33.122c0,12.021-18.405,41.336-33.122,61.603
            C154.137,89.458,135.731,60.143,135.731,48.122z M15,34.531C15,23.762,23.761,15,34.53,15h99.463
            c-8.208,8.635-13.262,20.296-13.262,33.122c0,7.455,3.886,17.633,9.306,28.209l-26.846,26.846
            c-0.003,0.002-0.005,0.004-0.008,0.007c-0.003,0.003-0.005,0.005-0.007,0.008l-86.695,86.695c-0.95-2.296-1.481-4.808-1.481-7.442
            V34.531z M34.53,201.975c-2.635,0-5.146-0.531-7.442-1.481l81.399-81.398l81.398,81.398c-2.296,0.95-4.808,1.481-7.443,1.481H34.53
            z M201.975,182.444c0,2.635-0.531,5.146-1.481,7.442l-81.398-81.399l18.555-18.554c10.69,17.785,22.918,33.86,25.262,36.903
            c1.42,1.843,3.614,2.923,5.941,2.923c2.327,0,4.521-1.08,5.941-2.923c2.485-3.227,16.085-21.106,27.181-40.141V182.444z"/>
          <path d="M63.964,98.25c20.009,0,36.287-16.278,36.287-36.287c0-4.142-3.357-7.5-7.5-7.5H63.964c-4.143,0-7.5,3.358-7.5,7.5
            c0,4.142,3.357,7.5,7.5,7.5h19.923C80.848,77.511,73.063,83.25,63.964,83.25c-11.737,0-21.286-9.549-21.286-21.287
            c0-11.737,9.549-21.286,21.286-21.286c5.684,0,11.03,2.214,15.052,6.234c2.93,2.928,7.678,2.928,10.607-0.002
            c2.928-2.929,2.927-7.678-0.002-10.606c-6.854-6.852-15.967-10.625-25.657-10.625c-20.008,0-36.286,16.278-36.286,36.286
            C27.678,81.972,43.956,98.25,63.964,98.25z"/>
        </g>
        </svg>
          Import with Google Maps URL
        </button>
        </div> */}
        {
            (extractedPlaces.length > 0 || isLoadingExtractedPlaces) && <div className="px-4 w-full pt-4 flex flex-col">
              <span className="font-bold text-sm pb-2">Adding places</span>
              {
                appliedExtractedPlaces.map((index) => {
                  const place = extractedPlaces[index]
                  return <AddingExtractedPlaceItem
                      title={place.title}
                      description={place.description}
                      address={place.address}
                      onDeleteClick={() => {
                        onDeleteExtractedPlace(index)
                      }}
                  />
              })
              }
              {
                isLoadingExtractedPlaces && <div key="loading">
                    <div className="flex gap-x-2 place-content-left p-4">
                    <span className="loading loading-spinner loading-md"></span>
                    <span className='font-normal text-base-content text-base'>Loading from website ...</span>
                    </div>
                </div>
              }
              {
                (extractedPlaces.length > 0 || isLoadingExtractedPlaces) && <div className="pt-2">
                  <div className="btn btn-neutral btn-sm normal-case" onClick={() => {
                    setSelectedExtractedPlaces(appliedExtractedPlaces)
                    setIsExtractedPlacesBottomSheetShown(true)
                  }}>View Options</div>
                </div>
              }
            </div>
        }
        {
            curationData && <div className="px-4 pt-4 w-full md:max-w-lg flex flex-col">
              <span className="font-bold text-sm pb-2">Adding to collection</span>
            <AnotherCurationItem
              title={curationData.curation.title}
              description={curationData.curation.description}
              emoji={curationData.curation.emoji}
              imageUrl={curationData.curation.coverPhoto}
              onClick={() => { onSelectCurationTap() }}
            />
            </div>
        }
        {
          curationData.filters.map((filter) => {
            return <FilterChipsContainer 
              modifier={"px-4 py-4"}
              key={filter.id}
              title={filter.label}
              filterOptions={filter.filterOptions}
              onFilterOptionClick={onFilterOptionClick}
            />
          })
        }
        <div className="h-24"></div>
        <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
          <button className="btn btn-success normal-case ml-4" onClick={() => onSaveClicked(selectedPlace, extractedPlaces.filter((_, index) => selectedExtractedPlaces.includes(index))) }>
              <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
              </svg>
              Save
          </button>
        </div>
        {
            <Sheet isOpen={isExtractedPlacesBottomSheetShown} onClose={() => {}} onOpenEnd={() => {
                console.log("open end");
            }}>
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                <Sheet.Scroller draggableAt="both">
            <div className="p-0">
            <div className="text-base lg:text-lg font-bold px-4 pt-4">Results</div>
            <div className="text-sm lg:text-base px-4 pt-2 pb-4">Select the places to add to collection</div>
            {
              isLoadingExtractedPlaces && <div key="loading">
                  <div className="flex gap-x-2 place-content-center p-4">
                  <span className="loading loading-spinner loading-md"></span>
                  <span className='font-normal text-base-content text-base'>Loading from website ...</span>
                  </div>
              </div>
            }
            {
                    extractedPlaces.map((place, index) => {
                        return <ExtractedPlaceItem
                            isSelected={selectedExtractedPlaces.includes(index)}
                            title={place.title}
                            description={place.description}
                            address={place.address}
                            onCheckChange={(event) => {
                              const {checked} = event.target;
                              onExtractedItemCheckChange(index, checked)
                            }}
                            onClick={(event) => {
                              onExtractedItemClick(index, selectedExtractedPlaces.includes(index))
                            }}
                        />
                    })
            }
            <div className="h-24"></div>
            <div className="modal-action absolute bottom-0 right-0 bg-base-100 w-full px-6 py-4">
            <button className="btn btn-success text-success-content mr-2" onClick={ () => {
                  setIsExtractedPlacesBottomSheetShown(false)
                  setAppliedExtractedPlaces(selectedExtractedPlaces)
                }
              } >Apply
            </button>
            <button className="btn btn-ghost" onClick={
                () => {
                  setIsExtractedPlacesBottomSheetShown(false)
                  setSelectedExtractedPlaces(appliedExtractedPlaces)
                }
                }>Close</button>
        </div>
        </div>
        </Sheet.Scroller>
                </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
        }
        {
          <FeedbackButton />
        }
      </div>
    )
}
