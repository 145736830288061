import React, { createContext, useState, useContext } from 'react';

const AddCurationContext = createContext();

export const useAddCurationContext = () => {
  return useContext(AddCurationContext);
};

export const AddCurationProvider = ({ children }) => {
  const [addCurationData, setAddCurationData] = useState({
    curation: {
      id: 0,
      title: "",
      description: "",
    }
  });

  return (
    <AddCurationContext.Provider value={{ addCurationData, setAddCurationData }}>
      {children}
    </AddCurationContext.Provider>
  );
};