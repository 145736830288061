export default function MemberItem(member, clickEvent) {
    return <div className="px-4 py-4 rounded-md bg-base-200 cursor-pointer hover:bg-base-300 md:grid md:grid-cols-5 md:items-center" onClick={clickEvent}>
        <div className={`md:col-span-5 md:flex md:flex-col md:justify-between md:min-h-full`}>
            <div className="top-section w-full py-2">
            {
                member.isOwner && <div className="flex items-center w-full pb-2">
                    <svg className="w-6 h-6">
                        <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                            <g className="fill-base-content" fill-rule="nonzero">
                                <path d="M17.0016031,15.2440856 L17.0009052,21.2451182 C17.0009052,21.8527788 16.3161092,22.2081862 15.8192057,21.8584172 L12.0007623,19.1706254 L8.18435794,21.8583162 C7.68747081,22.2082475 7.00251516,21.8528589 7.00251516,21.2451182 L7.00069412,15.2459273 C8.37018531,16.3435035 10.1084262,17 12,17 C13.8926316,17 15.6317588,16.3427691 17.0016031,15.2440856 Z M12,2 C15.8659932,2 19,5.13400675 19,9 C19,12.8659932 15.8659932,16 12,16 C8.13400675,16 5,12.8659932 5,9 C5,5.13400675 8.13400675,2 12,2 Z"></path>
                            </g>
                        </g>
                    </svg>
                    <div className="text-base-content text-sm font-bold text-base-content pl-2">{ "Owner" }</div>
                </div>
            }
                
                <div className="flex">
                    { !member.profilePicture && <div className="avatar placeholder">
                    <div className="bg-neutral text-neutral-content rounded-full w-12">
                        <span>{ member.name[0] }</span>
                    </div>
                    </div> 
                    }
                    { member.profilePicture && <div className="avatar">
                      <div className="w-12 rounded-full">
                        <img src={member.profilePicture} />
                      </div>
                    </div>
                    }
                    <div className="pl-4">
                        <div className="grow text-lg font-bold self-center text-base-content line-clamp-2">
                            { member.name }
                        </div>
                        <div className="grow text-base font-base self-center text-base-content line-clamp-2">
                            @{ member.handler }
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
    </div>
    
}