import FilterDropdownButton from './FilterDropdownButton';

export default function CurationListFilterBar({ activeFilterSize, onFilterButtonClick, onSortButtonClick }) {
    return (
        <div className="bg-base-100 pb-1 md:pb-2">
            <div direction={"row"} spacing={1} className='max-w-full overflow-scroll whitespace-nowrap space-x-2 mt-0 no-scrollbar flex'>
                <div className='flex-1 text-center'>
                <FilterDropdownButton 
                id={0} 
                label={"Sort"}
                isHighlighted={false} 
                onButtonClick={ () => { onSortButtonClick() } }
                icon={
                    <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-base-content' d="M16 18L16 6M16 6L20 10.125M16 6L12 10.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className='stroke-base-content' d="M8 6L8 18M8 18L12 13.875M8 18L4 13.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                }
                    />
                </div>
                <div className='flex-1 text-center'>
                <FilterDropdownButton
                id={1}
                label={"Filters"} 
                isHighlighted={false} 
                icon={
                    <svg className='w-5 h-5' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-base-content' d="M19 3H5C3.58579 3 2.87868 3 2.43934 3.4122C2 3.8244 2 4.48782 2 5.81466V6.50448C2 7.54232 2 8.06124 2.2596 8.49142C2.5192 8.9216 2.99347 9.18858 3.94202 9.72255L6.85504 11.3624C7.49146 11.7206 7.80967 11.8998 8.03751 12.0976C8.51199 12.5095 8.80408 12.9935 8.93644 13.5872C9 13.8722 9 14.2058 9 14.8729L9 17.5424C9 18.452 9 18.9067 9.25192 19.2613C9.50385 19.6158 9.95128 19.7907 10.8462 20.1406C12.7248 20.875 13.6641 21.2422 14.3321 20.8244C15 20.4066 15 19.4519 15 17.5424V14.8729C15 14.2058 15 13.8722 15.0636 13.5872C15.1959 12.9935 15.488 12.5095 15.9625 12.0976C16.1903 11.8998 16.5085 11.7206 17.145 11.3624L20.058 9.72255C21.0065 9.18858 21.4808 8.9216 21.7404 8.49142C22 8.06124 22 7.54232 22 6.50448V5.81466C22 4.48782 22 3.8244 21.5607 3.4122C21.1213 3 20.4142 3 19 3Z" strokeWidth="1.5"/>
                    </svg>
                }
                trailingIcon={
                    activeFilterSize > 0 ? <div className="badge badge-secondary">{ activeFilterSize }</div> : <div></div>
                }
                onButtonClick={ () => { onFilterButtonClick() } } />
                </div>
            </div>
        </div>
    );
  }
