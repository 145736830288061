import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { useEffect } from "react";

export default function GalleryCarouselPage({placeImages, onBigCarouselBackPressed, currentPage}) {

    useEffect(() => {
        carouselScrollToCurrentPage()
    });

    function carouselLeftButtonClick() {
        const carouselElement = document.getElementById("huge-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        if (scrollLeft - carouselWidth >= 0) {
            carouselElement.scrollTo(scrollLeft - carouselWidth, 0)
        }
    }

    function carouselRightButtonClick() {
        const carouselElement = document.getElementById("huge-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        carouselElement.scrollTo(scrollLeft + carouselWidth, 0)
    }

    function carouselScrollToCurrentPage() {
        const carouselElement = document.getElementById("huge-carousel")
        let carouselWidth = carouselElement.clientWidth
        carouselElement.scrollTo({
            left: currentPage * carouselWidth,
            top: 0,
            behavior: "instant"
        })
    }

    return (
        <div className="fixed top-0 bg-base-100 z-30 overflow-y-hidden w-screen">
            <div className="absolute top-0 w-full z-50">
            <CoorayAppBar title={""} onBackPressed={onBigCarouselBackPressed} navigationType={NavigationButtonType.Close}/>
            </div>
            <div className="carousel rounded-lg h-screen mt-3 w-full" id="huge-carousel">
                {
                    placeImages.map((image, index) => {
                    return <div className="carousel-item w-full h-full relative" id={`full-carousel-${index}`} key={`full-carousel-${index}`}>
                            { <LazyLoadImage className={`object-contain w-full h-full ${image.attributionHtml ? "pb-16": ""}`} src={image.url} alt={image.attribution} /> }
                            { image.attributionHtml && <div className="h-16 w-full p-4 text-center text-xs absolute bottom-0 bg-base-200/50" 
                                dangerouslySetInnerHTML={{__html: image.attributionHtml.map((link) => `Credit: ${link.replace("<a ", "<a target=\"_blank\"")}`).join("")}}></div> }
                        </div>
                    })
                }
            </div>
            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <div className="btn btn-circle" onClick={() => carouselLeftButtonClick()}>❮</div> 
                <div className="btn btn-circle" onClick={() => carouselRightButtonClick()}>❯</div>
            </div>
        </div>
    )
}
