export default function FullComment({comment, images, isPinned, shouldShowDeleteOption, shouldShowPinOption, onDeleteClick, onPinClick, onUnpinClick, onImageClick}) {
    return <div className={`w-full py-4`}>
        <div className="flex">
            <div className="flex w-full place-items-center px-4 grow">
                {
                    comment.user.profilePicture && <div className="avatar">
                        <div className="w-8 rounded-full">
                            <img src={comment.user.profilePicture} />
                        </div>
                    </div>
                }
                {
                    comment.user.profilePicture == null && <div className="avatar placeholder">
                        <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
                        <span className="text-base">{comment.user.name[0]}</span>
                        </div>
                    </div>
                }
                <div className="grow pl-4">
                    <div className="font-bold text-base">
                        {comment.user.handler}
                    </div>
                </div>
            </div>
            {
                isPinned && <div className="flex-none flex place-items-center pr-2">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="fill-base-content" d="M19.1835 7.80516L16.2188 4.83755C14.1921 2.8089 13.1788 1.79457 12.0904 2.03468C11.0021 2.2748 10.5086 3.62155 9.5217 6.31506L8.85373 8.1381C8.59063 8.85617 8.45908 9.2152 8.22239 9.49292C8.11619 9.61754 7.99536 9.72887 7.86251 9.82451C7.56644 10.0377 7.19811 10.1392 6.46145 10.3423C4.80107 10.8 3.97088 11.0289 3.65804 11.5721C3.5228 11.8069 3.45242 12.0735 3.45413 12.3446C3.45809 12.9715 4.06698 13.581 5.28476 14.8L6.69935 16.2163L2.22345 20.6964C1.92552 20.9946 1.92552 21.4782 2.22345 21.7764C2.52138 22.0746 3.00443 22.0746 3.30236 21.7764L7.77841 17.2961L9.24441 18.7635C10.4699 19.9902 11.0827 20.6036 11.7134 20.6045C11.9792 20.6049 12.2404 20.5358 12.4713 20.4041C13.0192 20.0914 13.2493 19.2551 13.7095 17.5825C13.9119 16.8472 14.013 16.4795 14.2254 16.1835C14.3184 16.054 14.4262 15.9358 14.5468 15.8314C14.8221 15.593 15.1788 15.459 15.8922 15.191L17.7362 14.4981C20.4 13.4973 21.7319 12.9969 21.9667 11.9115C22.2014 10.826 21.1954 9.81905 19.1835 7.80516Z"/>
                    </svg>
                </div>
            }
            {
                shouldShowDeleteOption && <div className="flex-none">
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <button tabIndex={0} className="btn btn-square btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current rotate-90"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
                        </button>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-auto font-base min-w-[200px]">
                            { !isPinned && shouldShowPinOption && <li><a onClick={() => {onPinClick(comment)}}>Pin comment</a></li> }
                            { isPinned && shouldShowPinOption && <li><a onClick={() => {onUnpinClick(comment)}}>Unpin comment</a></li> }
                            <li><a onClick={() => {onDeleteClick(comment.id)}}>Delete Comment</a></li>
                        </ul>
                    </div>
                </div>
            }
        </div>
        <div className="w-full text-base pt-2 px-4">
            {
                comment.content
            }
        </div>
        {
            images && images.length > 0 && <div className="carousel rounded-md h-24 w-full space-x-2 px-4 mt-4">
            {
                images.map((image, index) => {
                    return <div className="carousel-item" id={`thumbnail-${index}`} key={`thumbnail-${index}`} onClick={() => { onImageClick(comment, index) }}>
                        <img className="h-24 w-24 object-cover rounded-md" src={image.url} />
                    </div>
                })
            }
            </div>
        }
        {/* <div className="w-full text-base">
        <button className="btn btn-ghost normal-case" onClick={() => onReplyClick(comment)}>
        <svg className="w-4 h-4 inline fill-base-content" viewBox="0 0 512 512">
            <g>
                <path d="M442.539,73.946C397.7,36.018,333.59,13.863,255.205,13.881c-74.398,0-137.905,20.981-183.337,58.411
                    c-22.692,18.686-40.758,41.491-53.06,67.428C6.498,165.648-0.01,194.641,0,225.422c-0.018,37.214,7.777,71.586,23.511,101.577
                    c15,28.664,37.298,53.04,65.699,72.103c-6.715,28.269-16.148,68.67-16.148,68.689c-0.432,1.815-0.658,3.706-0.648,5.624
                    c-0.019,7.59,3.526,14.896,9.668,19.608l0.038,0.028l0.009,0.01c4.402,3.348,9.706,5.06,15,5.06c3.987,0,8.031-0.969,11.68-2.934
                    l0.367-0.188l0.357-0.216c0.01-0.01,5.069-3.01,12.856-7.618c11.671-6.903,29.435-17.407,45.291-26.745
                    c7.919-4.665,15.366-9.038,21.32-12.508c5.916-3.46,10.477-6.104,12.16-7.034l0.085-0.047l0.113-0.066
                    c22.1-12.526,39.704-14.633,53.849-14.708c58.692-0.085,121.212-11.464,171.13-42.62c24.894-15.602,46.561-36.413,61.806-63.056
                    c15.272-26.624,23.886-58.853,23.858-96.16C512.122,165.544,487.417,111.78,442.539,73.946z M454.709,301.24
                    c-8.812,15.375-20.37,28.316-34.232,39.282c-20.774,16.429-46.862,28.288-75.479,35.934c-28.598,7.664-59.642,11.088-89.792,11.078
                    c-18.517-0.075-44.379,3.414-72.761,19.683v-0.009c-3.14,1.749-10.128,5.822-19.345,11.219
                    c-13.222,7.759-30.62,18.019-45.15,26.614c3.545-15.094,7.655-32.576,10.73-45.413v-0.019c0.509-2.144,0.874-4.468,0.884-7.11
                    c0.037-3.724-0.922-8.266-3.075-12.046c-1.59-2.832-3.639-5.06-5.436-6.583c-2.736-2.285-4.796-3.273-5.944-3.903l-1.157-0.592
                    l-0.131-0.076C88.665,353.095,70.166,332.97,57.63,309.11c-12.517-23.896-19.1-51.78-19.11-83.689
                    c0.01-25.401,5.295-48.526,15.085-69.188c14.718-30.94,39.668-56.604,73.786-74.896c34.091-18.254,77.398-28.937,127.814-28.937
                    c71.276,0.019,125.933,20.031,162.45,50.952c36.47,31.025,55.702,72.799,55.824,120.864
                    C473.452,255.723,466.417,280.729,454.709,301.24z"/>
            </g>
            </svg>
            Reply
        </button>
        </div> */}
    </div>
}