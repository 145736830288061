import axios from "axios";
import { getAddPlaceCommentUrl, getDeletePlaceCommentUrl, getPinPlaceCommentUrl, getUnpinPlaceCommentUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class CommentRepository {
    async saveComment(comment, placeId, images) {
        const session = await supabase.auth.getSession()
        const formData = new FormData()
        images.forEach((image, index) => {
            formData.append(`image${index}`, image)
        });
        formData.append("comment", comment)
        const response = await axios.post(
            getAddPlaceCommentUrl(placeId),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data
    }

    async pinComment(commentId, placeId) {
        const session = await supabase.auth.getSession()
        await axios.post(
            getPinPlaceCommentUrl(placeId, commentId),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async unpinComment(commentId, placeId) {
        const session = await supabase.auth.getSession()
        await axios.post(
            getUnpinPlaceCommentUrl(placeId, commentId),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async deleteComment(commentId, placeId) {
        const session = await supabase.auth.getSession()
        await axios.delete(
            getDeletePlaceCommentUrl(placeId, commentId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }
}
