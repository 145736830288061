export default function MainComment({ comment, clampLine }) {
    console.log(comment)
    return <div className="flex pt-2">
            <div className="chat chat-start w-full">
                <div className="chat-image avatar">
                    <div className="w-8 h-8">
                        <div className="flex w-8 h-8 rounded-full justify-center bg-white/10">
                        {
                            comment.user.profilePicture && <div className="avatar">
                                <div className="w-8 rounded-full">
                                    <img src={comment.user.profilePicture} />
                                </div>
                            </div>
                        }
                        {
                            comment.user.profilePicture == null && <div className="avatar placeholder">
                                <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
                                <span className="text-base">{comment.user.name[0]}</span>
                                </div>
                            </div>  
                        }
                        </div>
                    </div>
                </div>
                <div className="chat-bubble max-w-full">
                    <div className="text-sm items-center line-clamp-3">
                    {comment.content}
                    </div>
                </div>
            </div>


    </div>
}