import { useState, useEffect } from "react"
import CurationRepository from "../../repository/CurationRepository"
import { useNavigate, useParams } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import InvitationRepository from "../../repository/InvitationRepository"
import InviteUserSuccessPage from "./InviteUserSuccessPage"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function InviteUserPage() {

    const [loadingText, setLoadingText] = useState(null)
    const [inputUserId, setInputUserId] = useState("")
    const [targetCuration, setTargetCuration] = useState(null)
    const [toastErrorMessage, setToastErrorMessage] = useState(null)
    const navigate = useNavigate()
    const { curationId }= useParams()
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
  
        const curationRepository = new CurationRepository()
        async function getCurations(curationId) {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const curation = await curationRepository.getCurations(curationId)
              console.log("curation")
              console.log(curation)
              setTargetCuration(curation.curation)
              setLoadingText(null)
            }
        }
        getCurations(curationId)
      }, [curationId])

    async function sendInvitation(username) {
        setLoadingText("Saving ...")
        const invitationRepository = new InvitationRepository()
        const response = await invitationRepository.sendInvitation(targetCuration.id, username)
        if (response.error == "USER_ALREADY_INVITED") {
            console.log(response.error)
            setToastErrorMessage(`${username} is already invited / has already joined the curation. No new invitation was sent.`)
        } else {
            setCurrentPage(1)
        }
        setLoadingText(null)
    }

    function onUserIdChange(event) {
        setToastErrorMessage("")
        setInputUserId(event.target.value)
    }

    function onSaveClick(event) {
        if (inputUserId) {
            sendInvitation(inputUserId)
        } else {
            setToastErrorMessage("Username / email cannot be empty")
        }
    }

    function onBackPressed() {
        navigate(`/collections/${curationId}/members`)
    }

    return currentPage == 1? (
        <InviteUserSuccessPage curationId={targetCuration.id} curationTitle={targetCuration.title} username={inputUserId}/>
    ) : (<div>
        <CoorayAppBar title={ "Invite Someone" } onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
        <div className="bg-base-200 px-4 py-4">
            <div className="max-w-full text-base-content text-base">{ targetCuration ? `Invite someone to ${targetCuration.title}` : ""}</div>
        </div>
        <div className="form-control w-full px-4">
            <label className="label">
                <span className="label-text">Provide the email / username to invite</span>
            </label>
            <input type="text" placeholder="e.g. timcook@apple.com..." className="input input-bordered w-full rounded-md" onChange={onUserIdChange} value={inputUserId}/>
        </div>
        <div className='pb-20'></div>
        <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
        <button className="btn btn-success normal-case ml-4" onClick={ onSaveClick }>
            <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send"><path className="fill-success-content" d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></svg>
            Send
        </button>
        </div>

        {toastErrorMessage &&
          <div className="toast toast-bottom toast-center w-full max-w-screen-md">
            <div className="alert alert-info whitespace-normal">
              <span>{toastErrorMessage}</span>
            </div>
          </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
    </div>)
}