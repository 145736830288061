import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import CurationListItem from "../curationitem/CurationListItem";
import MapCurationListItem from "../curationitem/MapCurationListItem";

export const MapPopup = ({ placeDetails, map, onPopupClick, shouldDisableClick, analytics }) => {
  console.log(`updating map popup ${map} ${JSON.stringify(placeDetails)}`)
  const popupRef = useRef();
  const insidePopupRef = useRef()

  useEffect(() => {

    const popup = new mapboxgl.Popup({
      closeButton: false,
      maxWidth: "500px",
    })
    .setLngLat({
      lat: placeDetails.placeDetails.coordinates.latitude,
      lng: placeDetails.placeDetails.coordinates.longitude
    })
    .setDOMContent(popupRef.current)
    .addTo(map);

    return popup.remove;
  }, [map, placeDetails]);

  return (
    <div style={{ display: "none" }}>
      <div className={`w-full`} ref={popupRef}>
        <MapCurationListItem 
          imageHeightClass="h-40"
          placeItem={placeDetails.placeDetails} 
          clickEvent={
            () => {
              if (!shouldDisableClick) {
                onPopupClick(placeDetails.placeDetails.id, placeDetails.placeDetails)
              }
            }
          }
          analytics={analytics}
          ref={insidePopupRef}
        />
      </div>
    </div>
  );
};

export default MapPopup;