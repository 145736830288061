export default function AutocompleteInput({items, placeholder, onValueChange, onItemSelected, value, loadingText, isBig}) {
    console.log(items)
    let content
    if (loadingText) {
        content = <li key="loading">
        <div className="flex gap-x-2 content-center">
        <span className="loading loading-spinner loading-md"></span>
        <span className='font-normal text-base-content text-base'>{ loadingText }</span>
        </div>
    </li>
    } else {
        content = items.map((item) => {
            console.log(`item ${JSON.stringify(item)}`)
            return <li key={item.key}>
                <div className="flex flex-col gap-y-1 rounded-md" onMouseDown={() => { onItemSelected(item); }}>
                    <div className="w-full text-base font-bold">{ item.label }</div>
                    <div className="w-full text-sm font-normal">{ item.secondaryLabel }</div>
                </div>
            </li>
        })
    }
    let iconSize
    let iconPadding
    if (isBig) {
        iconSize = "w-6 h-6 left-4"
        iconPadding = "pl-16 input-lg"
    } else {
        iconSize = "w-4 h-4 left-4 text-base"
        iconPadding = "pl-12"
    }
    return (
        <div className="dropdown w-full">
        <div className="form-control w-full relative">
            <svg className={`pointer-events-none absolute top-1/2 transform -translate-y-1/2 fill-none ${iconSize}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="stroke-base-content" d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <input className={`input input-bordered w-full rounded-md z-1 ${iconPadding}`} value={value} placeholder={placeholder} onChange={onValueChange} />
        </div>
        {
            (loadingText != null || items.length > 0) && <ul className="p-2 shadow menu dropdown-content z-[10] bg-base-100 rounded-box w-full text-base">
                { content }
            </ul>
        }
        </div>
      )
}
