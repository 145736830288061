import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import YourCurationsPage from "./YourCurationsPage";
import ExplorePage from "./ExplorePage";
import UserRepository from "../../repository/UserRepository";
import CoorayFooter from "../../shared/components/footer/CoorayFooter";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import FeedbackButton from "../../shared/components/feedback/FeedbackButton";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function HomePage({tab, analytics}) {

    const [currentPage, setCurrentPage] = useState(
        tab == "yours" ? 1 : 0
    )
    const [userProfile, setUserProfile] = useState(null)
    const [homeLoadingText, setHomeLoadingText] = useState(null)
    const [title, setTitle] = useState("Roambear")
    const navigate = useNavigate()

    useEffect(() => {
        const userRepository = new UserRepository()
  
        async function getUserProfile() {
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const profileResponse = await userRepository.getUserProfile()
            console.log(profileResponse)
            setUserProfile(profileResponse)
          }
        }
        // getUserProfile()
  
      }, [])

    function bottomNavigationItemClicked(index) {
        setCurrentPage(index)
        if (index == 0) {
            window.history.pushState(null, null, `/`)
        } else if(index == 1) {
            window.history.pushState(null, null, `/yours`)
        }
    }


    // window.addEventListener('popstate', function(_) {
    //     console.log(`href is ${window.location.href}`)
    //     if (window.location.href.includes("yours")) {
    //         setCurrentPage(1)
    //     } else {
    //         setCurrentPage(0)
    //     }
    // })

    function onBackPressed() {}

    async function onSignOutClick() {
        setHomeLoadingText("Logging out ...")
        const { error } = await supabase.auth.signOut()
        navigate("/login")
    }

    function onUserProfileLoaded(profile) {
        setUserProfile(profile)
    }

    function onTitleChange(title) {
        setTitle(title)
    }

    return (
        <div>
            <div className="navbar bg-base-100">
                <div className="flex-1 pl-2">
                    <img className="w-8 h-8" src="https://oesctluxvegwtcqckcdg.supabase.co/storage/v1/object/public/cooray-shared-resources/roambear_logo.png"/>
                    <div className="text-xl font-bold px-2">{ title }</div>
                </div>
                {
                    userProfile && <div className="flex-none">
                    <div tabIndex="0" role="button" className="btn btn-ghost md:btn-primary" onClick={() => {
                        navigate("/collections/add")
                    }}>
                        <div className="indicator">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path className="stroke-base-content md:stroke-primary-content" d="M4 12H20M12 4V20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </div>
                        <span className="hidden md:block text-base normal-case text-primary-content">Create Collection</span>
                    </div>
                    <div className="dropdown dropdown-end md:pl-4">
                        <div tabIndex="0" role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full border-4 border-primary flex">
                            { userProfile.user.profilePicture && <img src={  userProfile.user.profilePicture } /> }
                            { !userProfile.user.profilePicture && <span className="text-lg self-center w-full">{ userProfile.user.name[0] }</span> }
                            </div>
                            
                            {/* <div className="avatar placeholder w-10 h-10">
                            <div className="bg-neutral text-neutral-content rounded-full w-10 h-10">
                                <span className="text-xl">AI</span>
                            </div>
                            </div> */}
                        </div>
                        <ul tabIndex="0" className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            <li onClick={() => {
                                logEvent(analytics, AnalyticsEvent.VIEW_OWN_PROFILE_CLICK, {})
                                window.location.href = "/profile"
                            }}>
                            <div className="py-4">
                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_429_11217)">
                                <path className="stroke-base-content" d="M4 18C4 15.7908 5.79086 14 8 14H16C18.2091 14 20 15.7908 20 18V18C20 19.1045 19.1046 20 18 20H6C4.89543 20 4 19.1045 4 18V18Z" strokeWidth="2.5" strokeLinejoin="round"/>
                                <circle cx="12" cy="6.99997" r="3" strokeWidth="2.5" className="stroke-base-content"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_429_11217">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                                <span className="text-base">View Profile</span>
                                </div>
                            </li>
                            <li>
                                <div className="py-4" onClick={() => { 
                                    logEvent(analytics, AnalyticsEvent.LOGOUT_CLICK, {})
                                    onSignOutClick()
                                }}>
                                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="fill-base-content" d="M12.9999 2C10.2385 2 7.99991 4.23858 7.99991 7C7.99991 7.55228 8.44762 8 8.99991 8C9.55219 8 9.99991 7.55228 9.99991 7C9.99991 5.34315 11.3431 4 12.9999 4H16.9999C18.6568 4 19.9999 5.34315 19.9999 7V17C19.9999 18.6569 18.6568 20 16.9999 20H12.9999C11.3431 20 9.99991 18.6569 9.99991 17C9.99991 16.4477 9.55219 16 8.99991 16C8.44762 16 7.99991 16.4477 7.99991 17C7.99991 19.7614 10.2385 22 12.9999 22H16.9999C19.7613 22 21.9999 19.7614 21.9999 17V7C21.9999 4.23858 19.7613 2 16.9999 2H12.9999Z"/>
                                <path className="fill-base-content" d="M13.9999 11C14.5522 11 14.9999 11.4477 14.9999 12C14.9999 12.5523 14.5522 13 13.9999 13V11Z"/>
                                <path className="fill-base-content" d="M5.71783 11C5.80685 10.8902 5.89214 10.7837 5.97282 10.682C6.21831 10.3723 6.42615 10.1004 6.57291 9.90549C6.64636 9.80795 6.70468 9.72946 6.74495 9.67492L6.79152 9.61162L6.804 9.59454L6.80842 9.58848C6.80846 9.58842 6.80892 9.58778 5.99991 9L6.80842 9.58848C7.13304 9.14167 7.0345 8.51561 6.58769 8.19098C6.14091 7.86637 5.51558 7.9654 5.19094 8.41215L5.18812 8.41602L5.17788 8.43002L5.13612 8.48679C5.09918 8.53682 5.04456 8.61033 4.97516 8.7025C4.83623 8.88702 4.63874 9.14542 4.40567 9.43937C3.93443 10.0337 3.33759 10.7481 2.7928 11.2929L2.08569 12L2.7928 12.7071C3.33759 13.2519 3.93443 13.9663 4.40567 14.5606C4.63874 14.8546 4.83623 15.113 4.97516 15.2975C5.04456 15.3897 5.09918 15.4632 5.13612 15.5132L5.17788 15.57L5.18812 15.584L5.19045 15.5872C5.51509 16.0339 6.14091 16.1336 6.58769 15.809C7.0345 15.4844 7.13355 14.859 6.80892 14.4122L5.99991 15C6.80892 14.4122 6.80897 14.4123 6.80892 14.4122L6.804 14.4055L6.79152 14.3884L6.74495 14.3251C6.70468 14.2705 6.64636 14.1921 6.57291 14.0945C6.42615 13.8996 6.21831 13.6277 5.97282 13.318C5.89214 13.2163 5.80685 13.1098 5.71783 13H13.9999V11H5.71783Z"/>
                                </svg>
                                <span className="text-base">Logout</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                }
                </div>
            <div className="min-h-screen">
                {/* {
                    currentPage == 0 && <ExplorePage />
                } */}
                {
                    currentPage == 1 && <YourCurationsPage analytics={analytics} onProfileLoaded={onUserProfileLoaded} onTitleChange={onTitleChange}/>
                }
            </div>
            <CoorayFooter />
            {homeLoadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{homeLoadingText}</div>
            </div>
            }
            {
                <FeedbackButton />
            }
        </div>
    )
}
