import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { Helmet } from "react-helmet";
import FeedbackButton from "../../shared/components/feedback/FeedbackButton";

export default function EditPlaceSuccessPage({addedPlaceData, curationData}) {
    function onBackPressed() {}
    return (
        <div>
            <Helmet><title>{`Edit Successful`}</title></Helmet>
            <CoorayAppBar title={`Edited ${addedPlaceData.title} Succesfully`} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-lg text-base-content text-center pl-4 pr-4">Wonderful! <span className="font-bold">{addedPlaceData.title}</span> updated!</div>
            </div>
            <div className="w-full grid grid-cols-3 md:grid-cols-6 gap-x-4 gap-y-4 pl-4 pr-4 pt-8">
                <a href={`/collections/${curationData.curation.id}`} className="col-span-3 w-full md:max-w-xs place-self-end">
                    <button className="btn btn-active btn-primary w-full">View {curationData.curation.title}</button>
                </a>
                <a href={`/places/${addedPlaceData.id}`} className="col-span-3 w-full md:max-w-xs place-self-start">
                    <button className="btn btn-active btn-secondary col-span-3 w-full md:max-w-xs place-self-start">View {addedPlaceData.title}</button>
                </a>
            </div>
            {
            <FeedbackButton />
            }
        </div>
    )
}
