import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import UserRepository from "../../repository/UserRepository"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { logEvent } from "firebase/analytics"
import { Helmet } from "react-helmet"
import { LazyLoadImage } from "react-lazy-load-image-component"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function OwnProfilePage({analytics}) {

    const [loadingText, setLoadingText] = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
  
        const userRepository = new UserRepository()
        async function getProfile() {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const response = await userRepository.getUserProfile()
              console.log(response)
              setUserProfile(response.user)
              setLoadingText(null)
            }
        }
        getProfile()

        logEvent(analytics, AnalyticsEvent.OWN_PROFILE_VIEW, {})
      }, [])

    function onBackPressed() {
      navigate(-1)
    }

    return <div>
      <Helmet><title>{userProfile ? userProfile.name : "Profile"} | Roambear</title></Helmet>
        <CoorayAppBar 
        title={ "" }
        onBackPressed={onBackPressed}
        navigationType={NavigationButtonType.Close}
        endItems={[
          <button className="btn btn-ghost" onClick={() => {navigate(`/profile/edit`)} }>
          <svg className='w-6 h-6' viewBox="0 0 24 24">
          <path className="fill-base-content" fill-rule="evenodd" clip-rule="evenodd" d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"/>
          </svg>
        </button>
        ]}
        />
        {
          userProfile && <div className="px-4 gap-y-2 flex flex-col pt-4">
            { !userProfile.profilePicture && <div className="avatar placeholder self-center">
            <div className="bg-neutral text-xl text-neutral-content rounded-full w-24 border-4 border-base-content">
                <span>{ userProfile.name[0] }</span>
            </div>
            </div> 
            }
            { userProfile.profilePicture && <div className="avatar self-center">
              <div className="w-24 border-4 border-base-content rounded-full">
                <LazyLoadImage src={userProfile.profilePicture} />
              </div>
            </div>
            }
            <div className="text-sm font-normal px-4 md:px-12 text-center w-full pt-2">@{userProfile.handler}</div>
            <div className="text-xl font-bold px-4 md:px-12 text-center w-full pt-2">{userProfile.name}</div>
          </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
          <FeedbackButton />
        }
    </div>
}