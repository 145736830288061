import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import PlaceRepository from "../../repository/PlaceRepository";
import { createClient } from '@supabase/supabase-js'
import FilterChipsContainer from "../../shared/components/FilterChipsContainer";
import FullComment from "../../shared/components/fullcomment/FullComment";
import CommentRepository from "../../repository/CommentRepository";
import { intersect } from "../../shared/utils/ArrayUtils";
import GalleryCarouselPage from "../gallery/GalleryCarouselPage";
import CoorayFooter from "../../shared/components/footer/CoorayFooter";
import Sheet from 'react-modal-sheet';
import "../placedetails/css/PlaceDetailsPage.css"
import AddCommentModalContent from "./AddCommentModelContent";
import GoogleNavigateItem from "../../shared/components/googlenavigate/GoogleNavigateItem";
import AnotherCurationItem from "./AnotherCurationItem";
import CurationRepository from "../../repository/CurationRepository";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { Helmet } from "react-helmet";
import UserRepository from "../../repository/UserRepository";
import { usePlaceDetailsContext } from "./PlaceDetailsContext";
import { useCurationPlacesContext } from "../curation/CurationPlacesContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CurationPicker from "../curationpicker/CurationPicker";
import FeedbackButton from "../../shared/components/feedback/FeedbackButton";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function PlaceDetailsPage({viewingPlaceId, onBackPressed, analytics}) {
    console.log(`existing`)
    const {placesData, setPlacesData} = useCurationPlacesContext()
    const {placeDetails, setPlaceDetails} = usePlaceDetailsContext()

    const [curationDetails, setCurationDetails] = useState({
        authorUserId: null
    })

    const [loggedInUserId, setLoggedInUserId] = useState(null)
    const topRef = useRef()
    const mainDetailsContentRef = useRef()
    const [isMapUsingDarkMode, setIsMapUsingDarkMode] = useState(false)
    const [placeImages, setPlaceImages] = useState(placeDetails && placeDetails.images ? placeDetails.images : [])
    const [loadingText, setLoadingText] = useState(placeDetails? null: "Loading ...")
    const [placeComments, setPlaceComments] = useState([])
    const [pendingDeletionCommentId, setPendingDeletionCommentId] = useState(null)
    const [commentInput, setCommentInput] = useState("")
    const [lastUsedCommentInput, setLastUsedCommentInput] = useState("")
    const [commentToBeUploadedImages, setCommentToBeUploadedImages] = useState([])
    const [isSavingComment, setIsSavingComment] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [appBarDropdownItems, setAppBarDropdownItems] = useState([])
    const [isNavbarVisible, setIsNavbarVisible] = useState(false)
    const [isAddressCopied, setIsAddressCopied] = useState(false)
    const [bigCarouselPage, setBigCarouselPage] = useState(null)
    const [carouselImages, setCarouselImages] = useState([])
    const [placePreferences, setPlacePreferences] = useState(null)
    const [isShowingCurationPicker, setIsShowingCurationPicker] = useState(false)
    const [isCommentBottomSheetOpen, setIsCommentBottomSheetOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState(null)
    const navigate = useNavigate()
    const { id }= useParams()
    const placeId = viewingPlaceId ?? id

    useEffect(() => {
  
      const placeRepository = new PlaceRepository()
      const curationRepository = new CurationRepository()
      async function getCurations(placeAuthorId, curationId) {
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const curationResponse = await curationRepository.getCurations(curationId)
            console.log(`curation author: ${curationResponse.curation.authorUserId}`)
            setCurationDetails(curationResponse.curation)

            if (user.id == curationResponse.curation.authorUserId && user.id != placeAuthorId) {
                setAppBarDropdownItems([
                    {
                        label: "Copy place to another collection",
                        callback: () => {
                            logEvent(analytics, AnalyticsEvent.DETAILS_ADD_TO_ANOTHER_COLLECTION_CLICK, {
                                place_id: placeId
                            })
                            addPlaceToAnotherCollection()
                        }
                    },
                    {
                        label: "Edit place",
                        callback: () => {
                            logEvent(analytics, AnalyticsEvent.DETAILS_EDIT_PLACE_CLICK, {
                                place_id: placeId
                            })
                            navigate(`/places/${placeId}/edit`)
                        }
                    },
                    {
                        label: "Delete place",
                        callback: () => {
                            console.log("delete place callback")
                            logEvent(analytics, AnalyticsEvent.DETAILS_DELETE_PLACE_CLICK, {
                                place_id: placeId
                            })
                            document.getElementById(`confirm-place-deletion-modal`).showModal()
                        }
                    }
                ])
            }
          }
      }

      async function  addPlaceToAnotherCollection() {
        // console.log(`adding ${placeDetails.id} to another collection`)
        setIsShowingCurationPicker(true)
      }

      async function afterFetchingDetails(details) {
        const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            setLoggedInUserId(user.id)
            setPlaceImages(details.images ?? [])
            getPlaceComments(placeId)
            setLoadingText(null)
            getCurations(details.authorUserId, details.curationId)
            console.log(`comparing users ${user.id} ${details.authorUserId}`)
            if (user.id == details.authorUserId || curationDetails.authorUserId == user.id) {
                setAppBarDropdownItems([
                {
                    label: "Copy place to another collection",
                    callback: () => {
                        logEvent(analytics, AnalyticsEvent.DETAILS_ADD_TO_ANOTHER_COLLECTION_CLICK, {
                            place_id: placeId
                        })
                        addPlaceToAnotherCollection()
                    }
                },
                {
                    label: "Edit place",
                    callback: () => {
                        logEvent(analytics, AnalyticsEvent.DETAILS_EDIT_PLACE_CLICK, {
                            place_id: details.id
                        })
                        navigate(`/places/${placeId}/edit`)
                    }
                },
                {
                    label: "Delete place",
                    callback: () => {
                        console.log("delete place callback")
                        logEvent(analytics, AnalyticsEvent.DETAILS_DELETE_PLACE_CLICK, {
                            place_id: details.id
                        })
                        document.getElementById(`confirm-place-deletion-modal`).showModal()
                    }
                }
                ])
            } else {
                setAppBarDropdownItems([
                    {
                        label: "Copy place to another collection",
                        callback: () => {
                            logEvent(analytics, AnalyticsEvent.DETAILS_ADD_TO_ANOTHER_COLLECTION_CLICK, {
                                place_id: placeId
                            })
                            addPlaceToAnotherCollection()
                        }
                    },
                ])
            }
        }
      }

      async function getPlaceDetails(id) {
        //   setLoadingText("Loading ...")
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            setLoggedInUserId(user.id)
            try {
                const details = await placeRepository.getPlaceDetails(id)
                console.log("loaded")
                console.log(details)
                setPlaceDetails(details)
                afterFetchingDetails(details)
            } catch (e) {
                console.log(e.response.status)
                if (e.response.status == 403) {
                    navigate("/", {replace: true})
                }
            }
          }
      }

      async function getPlaceComments(id) {
        const commentResponse = await placeRepository.getPlaceComments(id)
        setPlaceComments(
            commentResponse.comments.filter((comment) => comment.authorUserId in commentResponse.users).map((comment) => {
                let images
                if (comment.id in commentResponse.commentImages) {
                    images = commentResponse.commentImages[comment.id]
                } else {
                    images = []
                }
                return {
                    ...comment,
                    images: images,
                    user: commentResponse.users[comment.authorUserId],
                    // shouldShowDeleteOption: userId == comment.authorUserId || userId == curationDetails.authorUserId
                }
            })
        )
      }

      async function getPlacePreferences(placeId) {
        console.log(`getting place preferences ${placeId}`)
        const placeRepository = new PlaceRepository()
        const preferences = await placeRepository.getPlacePreferences(placeId)
        console.log(`getting place preferences ${JSON.stringify(preferences)}`)
        setPlacePreferences(preferences)
      }

      const promises = []

      if (placeDetails == null || placeDetails.id != placeId) {
        promises.push(getPlaceDetails(placeId))
      } else {
        promises.push(afterFetchingDetails(placeDetails))
      }
      promises.push(getPlacePreferences(placeId))
      Promise.all(promises)

      logEvent(analytics, AnalyticsEvent.PLACE_DETAILS_VIEW, {
        place_id: placeId
      })

    }, [placeId])

    useEffect( ()=> {
        window.onpopstate = e => {
            console.log(`on pop ${window.location.href}`)
            if (!window.location.href.includes("#gallery")) {
              setBigCarouselPage(null)
            }
        }
        
        setIsMapUsingDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches)
        

    }, [])

    async function saveComment(comment) {
        setIsSavingComment(true)
        const commentRepository = new CommentRepository(supabase)
        const response = await commentRepository.saveComment(comment, placeId, commentToBeUploadedImages.map((image) => image.file))
        const updatedComments = placeComments.concat(
            [
                {
                    id: response.id,
                    content: response.comment,
                    user: response.author,
                    images: response.images
                }
            ]
        )
        setCommentToBeUploadedImages([])
        setPlaceComments(updatedComments)
        setCommentInput("")
        setIsSavingComment(false)
        setIsCommentBottomSheetOpen(false)
    }

    function onPlaceDetailsBackPressed() {
        window.history.back()
    }

    function onCommentInputChange(event) {
        setCommentInput(event.target.value)
    }

    function onAddCommentClick() {
        logEvent(analytics, AnalyticsEvent.DETAILS_ADD_COMMENT_CLICK, {
            place_id: placeDetails.id
        })
        setIsCommentBottomSheetOpen(true)
    }

    function onSaveClick() {
        if (commentInput) {
            saveComment(commentInput)
        }
    }

    async function uploadImageToPlace() {
        const imagefile = document.querySelector('#upload-image-input')
        const fileToBeUploaded = imagefile.files[0]
        console.log(`image file ${fileToBeUploaded}`)
        document.getElementById("confirm-upload-modal").close()
        setLoadingText("Uploading image ...")
        const placeRepository = new PlaceRepository()
        const uploadedImageResponse = await placeRepository.uploadPlaceImage(
            placeId, fileToBeUploaded
        )
        const updatedImages = placeImages.concat(uploadedImageResponse.images)
        setPlaceImages(updatedImages)
        setLoadingText(null)
        const container = document.getElementById("place-images-container")
        container && container.scrollTo(container.scrollWidth, 0)
    }

    function onFileInputChange(event) {
        console.log(`on file picked ${event.target.files[0]}`)
        setSelectedImage(event.target.files[0])
        document.getElementById("confirm-upload-modal").showModal()
    }

    function onThumbnailClick(clickedId) {
        console.log(`thumbnail click ${clickedId}`)
        const carouselElement = document.getElementById("big-carousel")
        let carouselWidth = carouselElement.clientWidth
    
        let targetXPixel = (carouselWidth * clickedId) + 1
    
        carouselElement.scrollTo(targetXPixel, 0)

        logEvent(analytics, AnalyticsEvent.DETAILS_IMAGE_THUMBNAIL_CLICK, {
            place_id: placeDetails.id,
            thumbnail_index: clickedId
        })
    }

    function carouselLeftButtonClick() {
        const carouselElement = document.getElementById("big-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        if (scrollLeft - carouselWidth >= 0) {
            carouselElement.scrollTo(scrollLeft - carouselWidth, 0)
        }
    }

    function carouselRightButtonClick() {
        const carouselElement = document.getElementById("big-carousel")
        let carouselWidth = carouselElement.clientWidth
        let scrollLeft = carouselElement.scrollLeft
        carouselElement.scrollTo(scrollLeft + carouselWidth, 0)
    }

    function onBigCarouselBackPressed() {
        window.history.back()
    }

    function onCarouselBigImagePressed(index) {
        console.log(`carousel big image pressed ${index}`)
        window.history.pushState("object or string", "Title", `/places/${placeId}#gallery`);
        setCarouselImages(placeImages)
        setBigCarouselPage(index)
        logEvent(analytics, AnalyticsEvent.DETAILS_BIG_IMAGE_CLICK, {
            place_id: placeDetails.id,
            big_image_index: index
        })
    }

    function onCancelAddCommentBottomSheetClick() {
        setIsCommentBottomSheetOpen(false)
        setLastUsedCommentInput(commentInput)
        setCommentInput("")
    }

    function onCommentImagesSelected(images) {
        setCommentToBeUploadedImages(commentToBeUploadedImages.concat(images))
    }

    function onCommentImageClick(comment, index) {
        setCarouselImages(comment.images)
        setBigCarouselPage(index)
        window.history.pushState(comment.content, comment.user.name, `/places/${placeId}#gallery`);
        logEvent(analytics, AnalyticsEvent.DETAILS_COMMENT_IMAGE_CLICK, {
            place_id: placeDetails.id
        })
    }

    function resetAddressCopyState() {
        setIsAddressCopied(false)
    }

    function onVisitChange(event) {
        logEvent(analytics, AnalyticsEvent.DETAILS_VISIT_TOGGLE_CLICK, {
            place_id: placeDetails.id
        })
        const {checked} = event.target;
        console.log(`ischecked ${checked}`)
        if (checked) {
            saveVisit()
        } else {
            removeVisit()
        }
      }

    async function deletePlace() {
        document.getElementById(`confirm-place-deletion-modal`).close()
        setLoadingText("Deleting ...")
        const placeRepository = new PlaceRepository()
        await placeRepository.deletePlace(id)
        const updatedPlaces = placesData.places.filter((place) => {
            return place.id != id
        })
        console.log(`updated places ${JSON.stringify(updatedPlaces)}`)
        setPlacesData({
            ...placesData, places: updatedPlaces
        })
        navigate(`/collections/${placeDetails.curationId}`)
    }

    async function saveVisit() {
        setLoadingText("Saving ...")
        const placeRepository = new PlaceRepository()
        await placeRepository.savePlaceVisit(id)
        setPlacePreferences(
            {
                ...placePreferences, isVisited: true
            }
        )
        setLoadingText(null)
    }

    async function removeVisit() {
        setLoadingText("Saving ...")
        const placeRepository = new PlaceRepository()
        await placeRepository.removePlaceVisit(id)
        setPlacePreferences(
            {
                ...placePreferences, isVisited: false
            }
        )
        setLoadingText(null)
    }

    async function deleteComment(commentId) {
        setLoadingText("Deleting comment ...")
        const commentRepository = new CommentRepository()
        await commentRepository.deleteComment(commentId, placeDetails.id)
        setPlaceComments(
            placeComments.filter((comment) => comment.id != commentId)
        )
        setLoadingText(null)
    }

    async function pinComment(comment) {
        console.log(comment)
        setLoadingText("Pinning comment ...")
        const commentRepository = new CommentRepository()
        await commentRepository.pinComment(comment.id, placeDetails.id)
        // setPlaceComments(
        //     placeComments.filter((comment) => comment.id != commentId)
        // )
        setPlacesData(
            {
                ...placesData, places: placesData.places.map((place) => {
                    let updatedPlace
                    if (place.id == placeId) {
                        updatedPlace = {
                            ...place, mainComment: comment
                        }
                    } else {
                        updatedPlace = place
                    }
                    console.log(updatedPlace)
                    return updatedPlace
                })
            }
        )
        setPlaceDetails(
            {
                ...placeDetails, peekCommentId: comment.id
            }
        )
        setLoadingText(null)
    }

    async function unpinComment(comment) {
        console.log(comment)
        setLoadingText("Unpinning comment ...")
        const commentRepository = new CommentRepository()
        await commentRepository.unpinComment(comment.id, placeDetails.id)
        // setPlaceComments(
        //     placeComments.filter((comment) => comment.id != commentId)
        // )
        setPlacesData(
            {
                ...placesData, places: placesData.places.map((place) => {
                    let updatedPlace
                    if (place.id == placeId) {
                        updatedPlace = {
                            ...place, mainComment: null
                        }
                    } else {
                        updatedPlace = place
                    }
                    console.log(updatedPlace)
                    return updatedPlace
                })
            }
        )
        setPlaceDetails(
            {
                ...placeDetails, peekCommentId: null
            }
        )
        setLoadingText(null)
    }

    async function onAddToAnotherCollectionSelected(targetCurationId, targetCurationName) {
        setIsShowingCurationPicker(false)
        setLoadingText(`Copying to ${targetCurationName}...`)
        const placeRepository = new PlaceRepository()
        await placeRepository.copyPlace(placeId, targetCurationId)
        setLoadingText(null)
        setToastMessage(`Successfully copied ${placeDetails.title} to ${targetCurationName}`)
        document.getElementById("generic-message-modal").showModal()
    }

    const handleScroll = () => {
        const currentScrollPos = window.scrollY
        if (currentScrollPos > 64) {
            setIsNavbarVisible(true)
        } else {
            setIsNavbarVisible(false)
        }
    }

    const handleContentScroll = () => {
        const currentScrollPos =  mainDetailsContentRef.current.scrollTop
        if (currentScrollPos > 64) {
            setIsNavbarVisible(true)
        } else {
            setIsNavbarVisible(false)
        }
    }

    useEffect( () => {
        window.addEventListener('scroll', handleScroll);
        mainDetailsContentRef.current.addEventListener('scroll', handleContentScroll);
    })

    return (
        <div className="bg-base-100 h-full w-full overflow-y-scroll pb-4" ref={mainDetailsContentRef}>
            <Helmet><title>{ placeDetails ? placeDetails.title : "Loading ..." } | Roambear</title></Helmet>
            <div ref={topRef} className={`w-full fixed z-10`}>
            <CoorayAppBar shouldHideTitleInSmall={!isNavbarVisible} shouldHideTitleInBig={!isNavbarVisible} title={placeDetails ? placeDetails.title: ""} onBackPressed={onPlaceDetailsBackPressed} navigationType={NavigationButtonType.Back} rightDropdownItems={appBarDropdownItems}/>
            </div>
            <div className="h-20"></div>
            {
                placeDetails && placeDetails.placeAuthor && <div className="flex content-center pb-2 px-4 md:px-12">
                    <div className="text-xs self-center md:text-sm text-base-content line-clamp-2 opacity-70 pb-1">
                        { `Added by @${placeDetails.placeAuthor.handler}` }
                    </div>
                </div>
            }
            <div className="text-xl font-bold px-4 md:px-12">{placeDetails ? placeDetails.title: ""}</div>
            {
                placeDetails && placeDetails.googlePlace && placeDetails.googlePlace.fullAddress &&
                <div className="px-4 flex pt-2 w-full text-sm self-center text-base-content md:px-12">
                    <span className="self-center pr-4">{placeDetails.googlePlace.fullAddress}</span>
                    {
                        !isAddressCopied && <svg className="h-5 w-5 self-center" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                navigator.clipboard.writeText(placeDetails.googlePlace.fullAddress)
                                setIsAddressCopied(true)
                                setTimeout(resetAddressCopyState, 1000)
                                logEvent(analytics, AnalyticsEvent.DETAILS_COPY_ADDRESS_CLICK, {
                                    place_id: placeDetails.id
                                })
                            }
                        }>
                        <path className="fill-base-content" fill-rule="evenodd" clip-rule="evenodd" d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"/>
                        <path className="fill-base-content" d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z" />
                        </svg>
                    }
                    {isAddressCopied && <svg className="h-5 w-5 self-center" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="stroke-base-content" d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>
            }
            {
                placeDetails && placeDetails.googlePlace && <div className="px-4 md:px-12 pt-4">
                    {
                        GoogleNavigateItem({reviewScore: placeDetails.googlePlace.reviewScore, onClick: (e) => { 
                            window.open(placeDetails.googlePlace.mapsLink, "_blank")
                            logEvent(analytics, AnalyticsEvent.DETAILS_GOOGLE_NAVIGATE_CLICK, {
                                place_id: placeDetails.id
                            })
                         }})
                    }
                </div>
            }
            <div className="grid grid-cols-1 md:grid-cols-2 mt-3 md:px-8 pb-20 min-h-[80vh]">
                <div className="row-span-2 md:row-span-1 md:order-last">
                    {
                        placeImages && placeImages.length > 0 && <div className="relative">
                            <div className="carousel md:rounded-lg h-96 carousel-center w-full overflow-y-hidden" id="big-carousel">
                                {
                                    placeImages.map((image, index) => {
                                    return <div className="carousel-item w-full h-full" id={`full-carousel-${index}`} key={`full-carousel-${index}`}>
                                            <div className="w-full h-96">
                                                { image.attributionHtml && <LazyLoadImage className="object-cover w-full h-80 rounded-md" src={image.url} alt={image.attribution} onClick={() => { onCarouselBigImagePressed(index) }}/> }
                                                { !image.attributionHtml && <LazyLoadImage className="object-cover w-full h-96 rounded-md" src={image.url} alt={image.attribution} onClick={() => { onCarouselBigImagePressed(index) }} /> }
                                                { image.attributionHtml && <div className="h-16 mt-auto w-full px-2 pt-2 text-center text-xs" 
                                                dangerouslySetInnerHTML={{__html: image.attributionHtml.map((link) => `Credit: ${link.replace("<a ", "<a target=\"_blank\"")}`).join("")}}></div> }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2 pointer-events-none">
                                <div className="btn btn-circle pointer-events-auto" onClick={() => carouselLeftButtonClick()}>❮</div> 
                                <div className="btn btn-circle pointer-events-auto" onClick={() => carouselRightButtonClick()}>❯</div>
                            </div>
                        </div>
                    }
                    {
                        placeImages.length > 0 && <div className="md:pr-4 pb-4">
                            {/* <input id="upload-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange}/> */}
                            <div id="place-images-container" className="carousel rounded-md h-24 w-full space-x-2">
                                {/* <div className="carousel-item w-2 rounded-md"></div> */}
                                <div className="carousel-item w-2 md:hidden"></div>
                            {
                                // !loadingText && placeImages.length > 0 && <div className="carousel-item">
                                //     <button className="btn btn-ghost h-24 w-24 p-0 rounded-md border-base-content border-dashed border-2 " onClick={() => {document.getElementById('upload-image-input').click();}}>
                                //     <div className="h-20 w-20 flex place-content-center items-center text-2xl font-bold rounded-md ">+</div>
                                //     </button>
                                // </div>
                            }
                            {
                                // !loadingText && placeImages.length == 0 && <div className="carousel-item">
                                //     <button className="btn btn-ghost h-24 w-32 p-0 rounded-md border-base-content border-dashed border-2 normal-case" onClick={() => {document.getElementById('upload-image-input').click();}}>
                                //     <div className="h-20 w-32 flex place-content-center items-center text-md font-bold rounded-md ">
                                //     <svg className="h-4 w-4 mr-1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="stroke-base-content group-hover:stroke-base-100" d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg> Add images</div>
                                //     </button>
                                // </div>
                            }
                            {
                                placeImages.map((image, index) => {
                                    return <div className="carousel-item" id={`thumbnail-${index}`} key={`thumbnail-${index}`} onClick={() => onThumbnailClick(index)}>
                                        <LazyLoadImage className="h-24 w-24 object-cover rounded-md" src={image.url} />
                                    </div>
                                })
                            }<div className="carousel-item w-2 md:hidden"></div></div>
                        </div>
                    }
                </div>
                <div className="md:row-span-1">
                    {
                        placeDetails && placeDetails.longDescription && <div className="mb-4">
                            <div className="px-4 flex py-2 w-full">
                                    <div className="grow text-base self-center text-base-content">
                                    {placeDetails.longDescription}
                                    </div>
                                </div>
                                </div>
                    }
                    {
                        placeDetails && placeDetails.filters && placeDetails.filters.length > 0 && <div className="md:rounded-md bg-base-200 md:mx-4 mb-4 px-4 pt-4"> {placeDetails.filters.map((filter) => {
                            return <div className="pb-4">
                                <h3 className="text-base font-bold">{filter.label}</h3>
                                <div className="pt-2">
                                    <FilterChipsContainer 
                                        key={filter.id}
                                        title={null}
                                        filterOptions={placeDetails.filterOptions.filter((option) => filter.filterOptions.includes(option.id))}
                                        onFilterOptionClick={() => {}}
                                        />
                                </div>
                            </div>

                        })}</div>
                    }
                    {
                        placePreferences && <div className="flex flex-col md:rounded-md bg-base-200 md:mx-4 mb-4 px-4 py-4">
                            <div className="form-control">
                            <label className="cursor-pointer label">
                                <span className="label-text text-base">Your have { placePreferences.isVisited ? "": "not " }visited this place</span> 
                                <input type="checkbox" className={`toggle ${ placePreferences.isVisited ? "toggle-primary": "toggle-base-content" }`} checked={placePreferences.isVisited} onChange={onVisitChange}/>
                            </label>
                            </div>
                        </div>
                    }
                    {
                        placeDetails && placeDetails.nearby && <div className="grid grid-cols-[auto,1fr] md:rounded-md bg-base-200 md:mx-4 mb-4 px-4 py-4 gap-y-2 gap-x-2">
                            { placeDetails.nearby.metro && placeDetails.nearby.metro.length > 0 && <div className="font-bold pr-4">🚇 Metro</div> }
                            { placeDetails.nearby.metro && placeDetails.nearby.metro.length > 0 && <div>{ placeDetails.nearby.metro.join(", ") }</div> }
                            { placeDetails.nearby.landmarks && placeDetails.nearby.landmarks.length > 0 && <div className="font-bold pr-4">🏛️ Landmarks</div> }
                            { placeDetails.nearby.landmarks && placeDetails.nearby.landmarks.length > 0 && <div>{ placeDetails.nearby.landmarks.join(", ") }</div> }
                        </div>
                    }
                    {
                    placeDetails && placeDetails.staticMaps && <div className="px-4 pb-4">
                        <div className="h-52 md:h-52 rounded-md relative text-center">
                            <img className="object-cover w-full h-full rounded-md" src={isMapUsingDarkMode ? placeDetails.staticMaps.dark : placeDetails.staticMaps.light}/>
                            <div className="absolute bottom-4 w-full text-center">
                            <button className="btn btn-primary normal-case text-base" onClick={() => {
                                window.open(placeDetails.googlePlace.mapsLink)
                                logEvent(analytics, AnalyticsEvent.DETAILS_VIEW_IN_MAPS_CLICK, {
                                    place_id: placeDetails.id
                                })
                            }}>View in Maps</button>
                            </div>
                        </div>
                    </div>
                    }
                    {
                        !loadingText && <div className="md:rounded-md mb-4">
                            <div className="text-base md:text-lg font-bold w-full border-b-2 border-base-content/10 md:border-transparent flex px-4 mb-2">
                                <div className="border-b-2 py-4 border-base-content shrink text-base">Comments</div>
                            </div>
                            <div className="md:mx-4 md:rounded-md bg-base-200">
                            {
                            placeComments.length > 0 && <div className="">
                                {
                                    placeComments.map((comment) => {
                                        console.log(`comment author ${comment.authorUserId}  ${curationDetails.authorUserId}`)
                                        return <FullComment 
                                            isPinned={ placeDetails.peekCommentId == comment.id }
                                            comment={ comment } 
                                            images={comment.images}
                                            onImageClick={ onCommentImageClick }
                                            onPinClick={ (comment) => {
                                                pinComment(comment)
                                            }}
                                            onUnpinClick={(comment) => {
                                                unpinComment(comment)
                                            }}
                                            onDeleteClick={ (id) => {
                                                setPendingDeletionCommentId(id)
                                                document.getElementById(`confirm-comment-deletion-modal`).showModal()
                                                logEvent(analytics, AnalyticsEvent.DETAILS_DELETE_COMMENT_CLICK, {
                                                    place_id: placeDetails.id
                                                })
                                            } }
                                            shouldShowPinOption={curationDetails.authorUserId == loggedInUserId}
                                            shouldShowDeleteOption={curationDetails.authorUserId == loggedInUserId || comment.authorUserId == loggedInUserId}
                                         />
                                    })
                                }
                            </div>
                            }
                            </div>
                            <div className="w-full flex place-items-center px-4 pt-4">
                                <button className="btn btn-primary normal-case text-base" onClick={() => {onAddCommentClick()}}>Add a comment</button>
                                {/* <input type="text" placeholder="Add a comment ..." className="input input-bordered rounded-md grow mr-4 text-base" onChange={onCommentInputChange} value={commentInput} disabled={isSavingComment}/>
                                {!isSavingComment && <span onClick={onAddCommentClick} className="float-right w-8 h-8 cursor-pointer group"><svg className="fill-base-content w-8 h-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send"><path className="fill-base-content" d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></svg></span>}
                                {isSavingComment && <span className="loading loading-infinity loading-md"></span> } */}
                            </div>
                        </div>
                    }
                    { placePreferences && placePreferences.otherCurationsWithPlace.length > 0 && <div className="gap-y-2 flex flex-col pb-4">
                            <div className="text-base md:text-lg font-bold w-full border-b-2 border-base-content/10 md:border-transparent flex px-4">
                                <div className="border-b-2 py-4 border-base-content shrink text-base">Also seen in</div>
                            </div>
                            {
                                placePreferences.otherCurationsWithPlace.map((item) => 
                                <div className="flex flex-col rounded-md px-0 md:px-4">
                                    <AnotherCurationItem 
                                        title={item.title}
                                        description={item.description}
                                        imageUrl={item.coverPhoto}
                                        emoji={item.emoji}
                                        onClick={() => {
                                            window.location.href= `/collections/${item.id}`
                                        }}
                                    />

                                </div>)
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="w-screen">
                    <CoorayFooter />
            </div>
            {
                <Sheet isOpen={isCommentBottomSheetOpen} onClose={() => onCancelAddCommentBottomSheetClick()} onOpenEnd={() => {
                    console.log("open end");
                    setCommentInput(lastUsedCommentInput)
                }}>
                    <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <AddCommentModalContent
                            onCommentInputChange={onCommentInputChange} 
                            commentInput={commentInput} 
                            onCancelClick={onCancelAddCommentBottomSheetClick}
                            onImagesSelected={onCommentImagesSelected}
                            commentImages={commentToBeUploadedImages}
                            onSaveClick={onSaveClick}
                            isSavingComment={isSavingComment}
                        />
                        {/* <input type="text" placeholder="Add a comment ..." className="input input-bordered rounded-md grow mr-4 text-base" onChange={onCommentInputChange} value={commentInput} disabled={isSavingComment}/>
                        {!isSavingComment && <span onClick={onAddCommentClick} className="float-right w-8 h-8 cursor-pointer group"><svg className="fill-base-content w-8 h-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send"><path className="fill-base-content" d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></svg></span>}
                        {isSavingComment && <span className="loading loading-infinity loading-md"></span> } */}
                    </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={(_) => {
                        onCancelAddCommentBottomSheetClick()
                    }}/>
                </Sheet>
            }
            {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content text-base mt-2'>{loadingText}</div>
            </div>
            }
            {
                <dialog id="confirm-upload-modal" className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <h3 className="font-bold text-lg">Confirm upload</h3>
                    { selectedImage && <img className="object-cover h-48 mt-2 rounded-md" src={URL.createObjectURL(selectedImage)} /> }
                    { selectedImage && <p className="py-2 text-base">Are you sure you would like to upload <span className="font-bold">{selectedImage.name}</span>?</p> }
                    <div className="modal-action">
                        <button className="btn btn-success" onClick={() => { uploadImageToPlace() }}>Yes</button>
                        <button className="btn" onClick={() => { document.getElementById("confirm-upload-modal").close() }}>Cancel</button>
                    </div>
                </div>
                </dialog>
            }
            {
                <dialog id="confirm-place-deletion-modal" className="modal">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <h3 className="font-bold text-lg">Confirm deletion?</h3>
                        <p className="py-2 text-base">Are you sure you want to delete <span className="font-bold">{placeDetails ? placeDetails.title: ""}</span>?</p>
                        <div className="modal-action">
                            <button className="btn btn-ghost" onClick={() => { deletePlace() }}>Yes</button>
                            <button className="btn btn-ghost" onClick={() => { document.getElementById("confirm-place-deletion-modal").close() }}>Cancel</button>
                        </div>
                    </div>
                </dialog>
            }
            {
                <dialog id="confirm-comment-deletion-modal" className="modal">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <h3 className="font-bold text-lg">Confirm deletion?</h3>
                        <p className="py-2 text-base">Are you sure you want to delete this comment?</p>
                        <div className="modal-action">
                            <button className="btn btn-ghost" onClick={() => { 
                                deleteComment(pendingDeletionCommentId)
                                document.getElementById("confirm-comment-deletion-modal").close()
                            }}>Yes</button>
                            <button className="btn btn-ghost" onClick={() => { document.getElementById("confirm-comment-deletion-modal").close() }}>Cancel</button>
                        </div>
                    </div>
                </dialog>
            }
            {
                bigCarouselPage != null && <GalleryCarouselPage placeImages={carouselImages} onBigCarouselBackPressed={onBigCarouselBackPressed} currentPage={bigCarouselPage}/>
            }
            {
                isShowingCurationPicker && <Sheet isOpen={isShowingCurationPicker} onClose={() => {setIsShowingCurationPicker(false)}} onOpenEnd={() => {}} detent="content-height">
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                <CoorayAppBar 
                title={ "Pick a collection" }
                onBackPressed={() => {
                    setIsShowingCurationPicker(false)  
                }}
                navigationType={NavigationButtonType.Close}
                />
                <Sheet.Scroller draggableAt="both">
                    <CurationPicker
                    shouldShowTitleAppBar={false}
                    analytics={analytics}
                    onPickerBackPressed={() => {}}
                    onPickerCurationSelected={(curationId, curationName) => {
                        onAddToAnotherCollectionSelected(curationId, curationName)
                        // getCurations(curationId)
                    }}
                />
                </Sheet.Scroller>
                </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={(_) => {
                    setIsShowingCurationPicker(false)
                }}/>
            </Sheet>
            }
            {<dialog id="generic-message-modal" className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <p className="py-2 text-base">{toastMessage}</p>
                    <div className="modal-action">
                        <button className="btn btn-ghost" onClick={() => { document.getElementById("generic-message-modal").close()}}>Ok</button>
                    </div>
                </div>
            </dialog>
            }
            {
            <FeedbackButton />
            }
        </div>
    )
}
