export const AnalyticsEvent = {

    // page views
    YOURS_VIEW: "yours_view",
    JOINED_VIEW: "joined_view",
    CURATION_VIEW: "curation_view",
    PLACE_DETAILS_VIEW: "place_details_view",
    ADD_PLACE_VIEW: "add_place_view",
    CURATION_MEMBERS_VIEW: "curation_members_view",
    PROFILE_VIEW: "profile_view",
    OWN_PROFILE_VIEW: "own_profile_view",
    EDIT_PROFILE_VIEW: "edit_profile_view",
    CURATION_PICKER_VIEW: "curation_picker_view",
    EDIT_CURATION_VIEW: "edit_curation_view",
    ADD_CURATION_VIEW: "add_curation_view",
    ADD_FILTER_VIEW: "add_filter_view",
    ADD_CURATION_SUCCESS_VIEW: "add_curation_success_view",
    ADD_PLACE_SUCCESS_VIEW: "add_curation_success_view",
    IMPORT_FROM_GOOGLE_MAPS_TUTORIAL_VIEW: "import_from_google_maps_tutorial_view",
    EDIT_PLACE_VIEW: "edit_place_view",
    COMPLETE_PROFILE_VIEW: "complete_profile_view",
    LOGIN_VIEW: "login_view",
    ENTER_INVITE_CODE_VIEW: "enter_invite_code_view",

    // actions
    SORT_BUTTON_CLICK: "sort_button_click",
    FILTER_BUTTON_CLICK: "filter_button_click",
    SORT_OPTION_CLICK: "sort_option_click",
    FILTER_APPLY_CLICK: "filter_apply_click",
    ADD_PLACE_CLICK: "add_place_click",
    CURATION_MEMBERS_CLICK: "curation_members_click",
    MAP_MARKER_CLICK: "map_marker_click",
    MAP_PLACE_ROW_CLICK: "map_place_row_click",
    MAP_MARKER_GOOGLE_NAVIGATE_CLICK: "map_marker_google_navigate_click",
    EDIT_CURATION_CLICK: "edit_curation_click",
    DELETE_CURATION_CLICK: "delete_curation_click",
    VIEW_OWN_PROFILE_CLICK: "view_own_profile_click",
    LOGOUT_CLICK: "logout_click",
    DETAILS_ADD_TO_ANOTHER_COLLECTION_CLICK: "details_add_to_another_collection_click",
    DETAILS_EDIT_PLACE_CLICK: "details_edit_place_click",
    DETAILS_VIEW_IN_MAPS_CLICK: "details_view_in_maps_click",
    DETAILS_GOOGLE_NAVIGATE_CLICK: "details_google_navigate_click",
    DETAILS_VISIT_TOGGLE_CLICK: "details_visit_toggle_click",
    DETAILS_ADD_COMMENT_CLICK: "details_add_comment_click",
    DETAILS_COPY_ADDRESS_CLICK: "details_copy_address_click",
    DETAILS_IMAGE_THUMBNAIL_CLICK: "details_image_thumbnail_click",
    DETAILS_BIG_IMAGE_CLICK: "details_big_image_click",
    DETAILS_COMMENT_IMAGE_CLICK: "details_comment_image_click",
    DETAILS_DELETE_COMMENT_CLICK: "details_delete_comment_click",
    DETAILS_DELETE_PLACE_CLICK: "details_delete_place_click",
    INVITE_MEMBER_CLICK: "invite_member_click",

    ADD_CURATION_UPLOAD_COVER_CLICK: "add_curation_upload_cover_click",
    ADD_CURATION_ADD_FILTER_CLICK: "add_curation_add_filter_click",
    ADD_FILTER_ADD_OPTION_CLICK: "add_filter_add_option_click",
    ADD_FILTER_SAVE_CLICK: "add_filter_save_click",
}