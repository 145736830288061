import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import FilterChipsContainer from "../../shared/components/FilterChipsContainer"
import AddFilterPage from '../addfilter/AddFilterPage'
import CurationRepository from '../../repository/CurationRepository'
import EditCurationSuccessPage from './EditCurationSuccessPage'
import { createClient } from '@supabase/supabase-js';
import EditFilterPage from '../editfilter/EditFilterPage';
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import { Helmet } from 'react-helmet';
import { useCurationContext } from '../curation/CurationContext';
import EmojiPicker from 'emoji-picker-react';
import FeedbackButton from '../../shared/components/feedback/FeedbackButton';

const IMAGE_MAX_DIMENSION = 700
const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function EditCurationPage({analytics}) {
    const [filters, setFilters] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [editingFilter, setEditingFilter] = useState(null)
    const {curationData, setCurationData} = useCurationContext()
    const [loadingText, setLoadingText] = useState(null)
    const [title, setTitle] = useState("")
    const [emoji, setEmoji] = useState("")
    const [coverPhoto, setCoverPhoto] = useState(null)
    const [description, setDescription] = useState("")
    const [updateSuccessCuration, setUpdateSuccessCuration] = useState(null)
    const { curationId }= useParams()
    const navigate = useNavigate()

    useEffect(() => {
  
      const curationRepository = new CurationRepository()
      async function getCurations(curationId) {
          setLoadingText("Loading ...")
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            let curation
            console.log(`curationData`)
            console.log(curationData)
            if (curationData && curationData.curation.id == curationId) {
                curation = curationData
            } else {
                curation = await curationRepository.getCurations(curationId)
            }
            console.log("curation")
            console.log(curation)
            if (curation.curation.authorUserId != user.id) {
                navigate(`/collections/${curation.curation.id}`)
            } else {
                setTitle(curation.curation.title)
                setDescription(curation.curation.description)
                setFilters(
                    curation.filters.map((filter) => {
                        return {
                            id: filter.id,
                            label: filter.label,
                            filterOptions: filter.filterOptions
                        }
                    })
                )
                setEmoji(curation.curation.emoji)
                setCoverPhoto(curation.curation.coverPhoto)
                setLoadingText(null)
            }
          }
      }
  
      logEvent(analytics, AnalyticsEvent.EDIT_CURATION_VIEW, {
        curation_id: curationId
      })

      getCurations(curationId)
    }, [curationId])

    async function saveCuration(curationId, curationTitle, curationDescription, curationFilters) {
        setLoadingText("Saving ...")
        console.log(`saving ${curationTitle}`)
        console.log(`saving ${curationDescription}`)
        console.log(`saving ${JSON.stringify(curationFilters)}`)
        const formattedFilters = curationFilters.map((filter) => {
            if (filter.id) {
                return {
                    id: filter.id
                }
            } else {
                return {
                    filter: {
                        filterName: filter.filterName,
                        options: filter.options
                    }
                }
            }
        })
        const curationRepository = new CurationRepository(supabase)
        const updatedCuration = await curationRepository.updateCuration(
            curationId, curationTitle, curationDescription, formattedFilters, emoji
        )
        setCurationData({
            ...curationData, curation: updatedCuration.curation
        })
        setUpdateSuccessCuration(updatedCuration)
        setCurrentPage(2)
    }

    function onFilterOptionClick(clickedOption) {
    }

    function onSaveClick() {
        saveCuration(
            curationId,
            title,
            description,
            filters.map((filter) => {
                return {
                    id: filter.id,
                    filterName: filter.label,
                    options: filter.filterOptions.map((option) => option.label)
                }
            })
        )
    }

    function onAddFilterClick() {
        console.log("on add filter click")
        setCurrentPage(1)
    }

    function onFilterSaveClick(filterName, options) {
        console.log(`on add filter save click ${filterName} ${options.length}`)
        const updatedFilters = filters.concat(
            [
                {
                    label: filterName,
                    filterOptions: options.map((option) => {
                        return {
                            label: option
                        }
                    })
                }
            ]
        )
        setFilters(updatedFilters)
        setCurrentPage(0)
    }

    function onBackPressed() {
        navigate(`/collections/${curationId}`, {replace: true})
    }

    function onAddFilterBackPressed() {
        setCurrentPage(0)
    }

    function onTitleChange(event) {
        console.log(`title input change ${event.target.value}`)
        setTitle(event.target.value)
    }

    function onDescriptionChange(event) {
        console.log(`description input change ${event.target.value}`)
        setDescription(event.target.value)
    }

    function onEmojiClick(emojiData, _) {
        console.log(emojiData)
        setEmoji(
            emojiData.emoji
        )
        document.getElementById(`pick-emoji-modal`).close();
    }

    function onEditFilterClick(filter) {
        console.log(`editing filter ${JSON.stringify(filter)}`)
        setEditingFilter(filter)
        setCurrentPage(3)
    }

    function onEditingFilterBackPressed() {
        console.log("editing filter back pressed")
        setCurrentPage(0)
    }

    function onEditFilterSaved(savedFilter) {
        console.log(`saved filter ${JSON.stringify(savedFilter)}`)
        const updatedFilters = filters.map((filter) => {
            if (filter.id == savedFilter.id) {
                return savedFilter
            } else {
                return filter
            }
        })
        setFilters(updatedFilters)
        setCurrentPage(0)
    }

    async function uploadImageToCuration(fileToBeUploaded) {
        // const imagefile = document.querySelector('#upload-image-input')
        // const fileToBeUploaded = imagefile.files[0]
        console.log(`image file ${fileToBeUploaded}`)
        setLoadingText("Uploading image ...")
        const curationRepository = new  CurationRepository()
        const updatedCuration = await curationRepository.uploadCoverPicture(
            curationId, fileToBeUploaded
        )
        setCoverPhoto(updatedCuration.curation.coverPhoto)
        setLoadingText(null)
    }

    function onFileInputChange(inputEvent) {
        // console.log(`on file picked ${event.target.files[0]}`)
        // setSelectedImage(event.target.files[0])
        // uploadImageToPlace()
  
        if (inputEvent.target.files) {
          let imageFile = inputEvent.target.files[0];
          var reader = new FileReader();
          reader.onload = function (e) {
              var img = document.createElement("img");
              img.onload = function (event) {
                  var width = img.width;
                  var height = img.height;
  
                  // Change the resizing logic
                  if (width > height) {
                      if (width > IMAGE_MAX_DIMENSION) {
                          height = height * (IMAGE_MAX_DIMENSION / width);
                          width = IMAGE_MAX_DIMENSION;
                      }
                  } else {
                      if (height > IMAGE_MAX_DIMENSION) {
                          width = width * (IMAGE_MAX_DIMENSION / height);
                          height = IMAGE_MAX_DIMENSION;
                      }
                  }
  
                  var canvas = document.createElement("canvas");
                  canvas.width = width;
                  canvas.height = height;
                  var ctx = canvas.getContext("2d");
                  ctx.drawImage(img, 0, 0, width, height);
  
                  canvas.toBlob((blob) => {
                    let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })
                    uploadImageToCuration(file)
                  }, 'image/jpeg');
  
                  // Show resized image in preview element
                  // var dataurl = canvas.toDataURL(imageFile.type);
                  // document.getElementById("preview").src = dataurl;
              }
              img.src = e.target.result;
          }
          reader.readAsDataURL(imageFile);
      }
  
    }

    let content
    switch (currentPage) {
        case 1:
            content = <AddFilterPage onFilterSaved={onFilterSaveClick} onBackPressed={onAddFilterBackPressed}/>
            break
        case 2:
            content = <EditCurationSuccessPage curationData={updateSuccessCuration} />
            break
        case 3:
            content = <EditFilterPage curationId={curationId} editingFilter={editingFilter} onBackPressed={onEditingFilterBackPressed} onFilterSaved={onEditFilterSaved}/>
            break
        default:
            content = <div>
                <Helmet><title>{ title ? `Editing ${title}` : "Edit Collection" } | Roambear</title></Helmet>
                <CoorayAppBar title={"Edit Curation"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
                <div className="font-bold px-4 py-4">The Essentials</div>
                <div className="form-control w-full px-4">
                    <label className="label">
                        <span className="label-text">Cooray name</span>
                    </label>
                    <input type="text" placeholder="e.g. Best cafes in Singapore ..." className="input input-bordered w-full rounded-md" onChange={onTitleChange} value={title}/>
                </div>
                <div className="form-control w-full px-4 pt-2">
                    <label className="label">
                        <span className="label-text">Description</span>
                    </label>
                    <input type="text" placeholder="e.g. A list of all the wonderful cafes in 🇸🇬 ..." className="input input-bordered w-full rounded-md" onChange={onDescriptionChange} value={description}/>
                </div>
                <div className="form-control w-full px-4 pt-2 ">
                    <label className="label">
                        <span className="label-text">Cover picture</span>
                    </label>
                    <input id="upload-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange}/>
                    <div className='pt-2'>
                    { coverPhoto && <div className="avatar">
                    <div className="w-24 border-4 border-base-content rounded-full">
                        <img src={coverPhoto} />
                    </div>
                    </div>
                    }
                    </div>
                    <div className='pt-2'>
                    <button className="btn btn-primary normal-case w-auto" onClick={() => {
                        document.getElementById('upload-image-input').click();
                    }}>Upload Picture</button>
                    </div>
                </div>
                <div className="font-bold px-4 py-4">Filters</div>
                <div className="px-4">
                    {
                        filters.map((filter) => {
                            return <div className='mb-4 rounded-lg bg-base-200 px-4 py-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='font-bold text-base pb-2'>{filter.label}</div>
                                    <svg className="w-4 h-4 fill-base-content" viewBox="0 -0.5 21 21" version="1.1" onClick={() => onEditFilterClick(filter)}>
                                        <g stroke="none" strokeWidth="1" fillRule="evenodd">
                                            <g id="Dribbble-Light-Preview" transform="translate(-99.000000, -400.000000)">
                                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                                    <path d="M61.9,258.010643 L45.1,258.010643 L45.1,242.095788 L53.5,242.095788 L53.5,240.106431 L43,240.106431 L43,260 L64,260 L64,250.053215 L61.9,250.053215 L61.9,258.010643 Z M49.3,249.949769 L59.63095,240 L64,244.114985 L53.3341,254.031929 L49.3,254.031929 L49.3,249.949769 Z" id="edit-[#1479]"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <FilterChipsContainer 
                                    key={filter.id}
                                    title={null}
                                    filterOptions={filter.filterOptions}
                                    onFilterOptionClick={onFilterOptionClick}
                                />
                            </div>
                        })
                    }
                    <button className="btn btn-primary" onClick={() => {onAddFilterClick()}}>Add Filter</button>
                </div>
                <div className="font-bold px-4 pb-4 pt-8">Marker Emoji</div>
                <div className="px-4 pb-4">
                <div className="avatar">
                    <div className="rounded-full bg-base-200 p-4">
                        <div className='font-bold text-6xl'>{emoji}</div>
                    </div>
                </div>
                <div className='pt-2'>
                    <button className="btn btn-primary normal-case w-auto" onClick={() => {
                        document.getElementById(`pick-emoji-modal`).showModal();
                    }}>Pick emoji</button>
                </div>
                </div>
                <div className='pb-20'></div>
                <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
                <button className="btn btn-success normal-case ml-4" onClick={ onSaveClick }>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                    </svg>
                    Save
                </button>
                </div>

                {loadingText &&
                <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                    <div className="loading loading-infinity loading-lg"></div>
                    <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                </div>
                }

                <dialog id="pick-emoji-modal" className="modal">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <h3 className="font-bold text-lg pb-4">Pick Marker Emoji</h3>
                        <EmojiPicker width={"100%"} theme={window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark": "light"} onEmojiClick={onEmojiClick} searchDisabled={true}/>
                        <div className="modal-action">
                            <button className="btn btn-ghost" onClick={() => { document.getElementById("pick-emoji-modal").close() }}>Cancel</button>
                        </div>
                    </div>
                </dialog>
            </div>
            break;
    }

    return <div>
        {content}
        {
          <FeedbackButton />
        }
    </div>
}
