export default function FilterDropdownButton({ icon, trailingIcon, label, isHighlighted, onButtonClick }) {
  let buttonType
  if (!isHighlighted) {
    buttonType = "bg-transparent hover:bg-transparent text-base-content"
  } else {
    buttonType = "btn-primary text-primary-content"
  }
  return <button className={`btn btn-ghost btn-base-content text-base rounded-md normal-case group ${buttonType} border-0 h-10 min-h-[2.5rem]`} onClick={onButtonClick}>
    { icon }
    { label }
    { trailingIcon }
  </button>;
}
