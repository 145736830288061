import PlaceRepository from "../../repository/PlaceRepository"
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import { useState, useEffect } from "react"
import { createClient } from "@supabase/supabase-js"
import { useNavigate, useParams } from "react-router-dom"
import CommentRepository from "../../repository/CommentRepository"
import InputCommentSuccessPage from "./InputCommentSuccessPage"

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function InputCommentPage() {
    const [placeData, setPlaceData] = useState({
      title: ""
    })
    const [inputComment, setInputComment] = useState("")
    const [loadingText, setLoadingText] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const { placeId }= useParams()
    const navigate = useNavigate()

    useEffect(() => {
      const placeRepository = new PlaceRepository()
      async function getPlaceDetails(id) {
          setLoadingText("Loading ...")
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const details = await placeRepository.getPlaceDetails(id)
            console.log(details)
            setPlaceData(details)
            setLoadingText(null)
          }
      }
      getPlaceDetails(placeId)
    }, [placeId])

    function handleCommentInputChange(event) {
      setInputComment(event.target.value)
    }

    function onBackPressed() {
      navigate(`/places/${placeId}`)
    }

    async function addComment(comment) {
      setLoadingText("Saving ...")
      const repository = new CommentRepository()
      await repository.saveComment(
        comment,
        placeId
      )
      setCurrentPage(placeData)
    }

    function onSaveClick() {
      if (inputComment) {
        addComment(inputComment)
      }
    }

    if (currentPage == 0) {
      return (
          <div>
          <CoorayAppBar title={"Add Comment"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
          <div className="bg-base-200 px-4 py-4">
              <div className="max-w-full text-base-content text-base flex content-center">
              <svg className="w-6 h-6 inline mr-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="fill-base-content" d="M10.968 18.769C15.495 18.107 19 14.434 19 9.938a8.49 8.49 0 0 0-.216-1.912C20.718 9.178 22 11.188 22 13.475a6.1 6.1 0 0 1-1.113 3.506c.06.949.396 1.781 1.01 2.497a.43.43 0 0 1-.36.71c-1.367-.111-2.485-.426-3.354-.945A7.434 7.434 0 0 1 15 19.95a7.36 7.36 0 0 1-4.032-1.181z"/><path className="fill-secondary-content" d="M7.625 16.657c.6.142 1.228.218 1.875.218 4.142 0 7.5-3.106 7.5-6.938C17 6.107 13.642 3 9.5 3 5.358 3 2 6.106 2 9.938c0 1.946.866 3.705 2.262 4.965a4.406 4.406 0 0 1-1.045 2.29.46.46 0 0 0 .386.76c1.7-.138 3.041-.57 4.022-1.296z" /></svg>
              <span>Add a comment about {placeData.title}</span>
              </div>
          </div>
          <div className="form-control w-full px-4 py-4">
            <label className="label">
              <span className="label-text font-bold text-sm">Comment</span>
            </label>
            <textarea value={inputComment} className="textarea textarea-bordered h-32 text-base rounded-md" placeholder="e.g. They serve pretty good ice cream ..." onChange={handleCommentInputChange}></textarea>
          </div>
          <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
                  <button className="btn btn-success normal-case ml-4" onClick={() => onSaveClick() }>
                      <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                          <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                      </svg>
                      Save
                  </button>
          </div>
          {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
              <div className="loading loading-infinity loading-lg"></div>
              <div className='font-normal text-base-content mt-2'>{loadingText} ...</div>
            </div>
            }
        </div>
      )
    } else {
      return <InputCommentSuccessPage placeData={placeData}/>
    }
}
