export default function AddingExtractedPlaceItem({ title, description, address, onDeleteClick }) {
    console.log(`extracted place ${title}`)
    return <div className="card card-side rounded-none md:rounded-md bg-base-200 hover:bg-base-300 cursor-pointer h-full px-4">
            <div className="card-body px-0 py-4 self-center">
                <div>
                    <h2 className="card-title font-bold text-base md:text-lg">{title}</h2>
                    <div className="text-sm md:text-base line-clamp-1">{description}</div>
                    <p className="text-sm md:text-base pt-2">
                        {
                            <span className="inline-flex items-baseline pr-2 text-sm md:text-base opacity-75">
                                <svg className="w-4 h-4 self-center mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="stroke-base-content" d="M16 13.3744C19.5318 14.0688 22 15.6547 22 17.5C22 19.9853 17.5228 22 12 22C6.47715 22 2 19.9853 2 17.5C2 15.6547 4.46819 14.0688 8 13.3744M12 17V3L17.3177 6.27244C17.7056 6.51114 17.8995 6.63049 17.9614 6.78085C18.0154 6.912 18.0111 7.0599 17.9497 7.18771C17.8792 7.33426 17.6787 7.44222 17.2777 7.65815L12 10.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="line-clamp-1">{ address }</span>
                            </span>
                        }
                    </p>
                </div>
            </div>
            <button className="btn btn-ghost h-8 w-8 p-0 rounded-md self-center" onClick={() => { onDeleteClick() }}>
                <svg className="w-8 h-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path className="fill-base-content" d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"/>
                </svg>
            </button>
        </div>
}
