import HomeCurationItem from "../../shared/components/curation/HomeCurationItem";
import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CurationRepository from "../../repository/CurationRepository";
import PendingInvitations from "../../shared/components/pendinginvitations/PendingInvitations";
import UserRepository from "../../repository/UserRepository";
import FilterOptionChip from "../../shared/components/filteroptionchip/FilterOptionChip";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { Helmet } from "react-helmet";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function YourCurationsPage({ analytics, onProfileLoaded, onTitleChange }) {

    const [loadingText, setLoadingText] = useState("Loading ...")
    const [userCurations, setUserCurations] = useState([])
    const [joinedCurations, setJoinedCurations] = useState([])
    const [pendingInvitations, setPendingInvitations] = useState([])
    const [isInvitationSectionSaving, setIsInvitationSectionSaving] = useState(false)
    const [isFirstLoading, setIsFirstLoading] = useState(true)
    const [isShowingYourOwnCurationList, setIsShowingYourOwnCurationList] = useState(true)
    const navigate = useNavigate()

    async function getUserCurations() {
        const curationRepository = new CurationRepository()
        setLoadingText("Loading ...")
        const curationResponse = await curationRepository.getUserCurations()
        console.log(curationResponse)
        // setCurationData(curation)
        // setSelectedFilterOptionIds(details.filterOptions.map((placeSelectedFilterOption) => placeSelectedFilterOption.id))
        setUserCurations(
            curationResponse.ownedCurations.map((curation) => {
                let placeCount
                let memberCount
                if (curationResponse.curationPlaceCount[curation.id]) {
                    placeCount = curationResponse.curationPlaceCount[curation.id]
                } else {
                    placeCount = 0
                }
                if (curationResponse.curationMemberCount[curation.id]) {
                    memberCount = curationResponse.curationMemberCount[curation.id]
                } else {
                    memberCount = 0
                }
                
                return {
                    ...curation, placeCount: placeCount, memberCount: memberCount
                }
            })
        )

        setJoinedCurations(
            curationResponse.joinedCurations.map((curation) => {

                let placeCount
                let memberCount
                if (curationResponse.curationPlaceCount[curation.id]) {
                    placeCount = curationResponse.curationPlaceCount[curation.id]
                } else {
                    placeCount = 0
                }
                if (curationResponse.curationMemberCount[curation.id]) {
                    memberCount = curationResponse.curationMemberCount[curation.id]
                } else {
                    memberCount = 0
                }
                return {
                    ...curation, placeCount: placeCount, memberCount: memberCount
                }
            })
        )
        setLoadingText(null)
        setIsFirstLoading(false)
        onTitleChange("Your Collections")
      }

    useEffect(() => {
  
        async function getUserProfile() {
          const {
            data: { user },
          } = await supabase.auth.getUser()
          if (user == null) {
            navigate("/login", {replace: true})
          } else {
            const userRepository = new UserRepository()
            try {
                const profileResponse = await userRepository.getUserProfile()
                console.log(profileResponse)
                onProfileLoaded(profileResponse)
            } catch(e) {
                console.log(`get profile error ${e.response.status}`)
                navigate("/signup/completion", {replace: true})
            }
          }
        }
        // getUserProfile()

        async function getPendingInvitations() {
            try {
                const userRepository = new UserRepository()
                const pendingInvitationResponse = await userRepository.getUserPendingInvitations()
                console.log(`${JSON.stringify(pendingInvitationResponse)}`)
                setPendingInvitations(
                    pendingInvitationResponse.invitations
                )
            } catch(e) {

            }
        }
        console.log(`refetching everything`)
        Promise.all(
            [
                getPendingInvitations(),
                getUserCurations(),
                getUserProfile()
            ]
        )

        window.addEventListener('popstate', function(_) {
            if (!this.window.location.href.includes("#joined")) {
                setIsShowingYourOwnCurationList(true)
            }
        })
    }, [])

    useEffect(() => {
        if (isShowingYourOwnCurationList) {
            console.log("logging yours view")
            logEvent(analytics, AnalyticsEvent.YOURS_VIEW);
        } else {
            console.log("logging joined view")
            logEvent(analytics, AnalyticsEvent.JOINED_VIEW);
        }

        if (!isFirstLoading) {
            if (isShowingYourOwnCurationList) {
                onTitleChange("Your Collections")
            } else {
                onTitleChange("Joined Collections")
            }
        }

    }, [isShowingYourOwnCurationList])

    async function saveAcceptInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.acceptInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setIsShowingYourOwnCurationList(false)
        setPendingInvitations(
            pendingInvitations.map((invitation) => {
                if (invitation.id == targetInvitation.id) {
                    return {
                        ...invitation, isAccepted: true
                    }
                } else {
                    return invitation
                }
            })
        )
        getUserCurations()
    }

    async function saveDeclineInvitation(targetInvitation) {
        const userRepository = new UserRepository()
        setIsInvitationSectionSaving(true)
        await userRepository.declineInvitation(targetInvitation.id)
        setIsInvitationSectionSaving(false)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }

    function onDismissInvitationClick(targetInvitation) {
        console.log(`dismiss invitation ${JSON.stringify(targetInvitation)}`)
        setPendingInvitations(
            pendingInvitations.filter((invitation) => invitation.id != targetInvitation.id)
        )
    }
    function onAcceptInvitationClick(targetInvitation) {
        saveAcceptInvitation(targetInvitation)
    }

    function onDeclineInvitationClick(targetInvitation) {
        saveDeclineInvitation(targetInvitation)
    }

    return (
        <div className="bg-base-100">
            <Helmet>
                {
                    isFirstLoading && <title>Roambear | Mark your spots</title>
                }
                {
                    !isFirstLoading && isShowingYourOwnCurationList && <title>Your Collections | Roambear</title>
                }
                {
                    !isFirstLoading && !isShowingYourOwnCurationList && <title>Joined Collections | Roambear</title>
                }
            </Helmet>
            {
                !isFirstLoading && <div className="px-4 py-4 space-x-2">
                <FilterOptionChip 
                    key={0}
                    chipId={0}
                    label={`${isShowingYourOwnCurationList? "✓ " : ""}Yours`}
                    isSelected={isShowingYourOwnCurationList}
                    onFilterOptionClick={() => {
                        setIsShowingYourOwnCurationList(true)}
                    }
                    onEndIconClick={
                        (_) => {}
                    }
                />
                <FilterOptionChip 
                    key={1}
                    chipId={0}
                    label={`${!isShowingYourOwnCurationList? "✓ " : ""}Joined`}
                    isSelected={!isShowingYourOwnCurationList}
                    onFilterOptionClick={() => {
                        window.history.pushState(null, null, `#joined`)
                        setIsShowingYourOwnCurationList(false)
                    }}
                    onEndIconClick={
                        (_) => {}
                    }
                />
            </div>
            }
            {pendingInvitations.length > 0 && <div className="pb-2">
                <PendingInvitations
                    pendingInvitations={pendingInvitations}
                    onDismissInvitationClick={onDismissInvitationClick}
                    onAcceptInvitationClick={onAcceptInvitationClick}
                    onDeclineInvitationClick={onDeclineInvitationClick}
                    isSaving={isInvitationSectionSaving}
                />
            </div>}
            <div className="grid grid-cols-3 md:grid-cols-6 gap-2 md:px-4 pb-20 items-stretch">
                {
                    (isShowingYourOwnCurationList ? userCurations : joinedCurations).map((curation) => {
                        const emojiText = curation.emoji != null ? `${curation.emoji} ` : ""
                        return <div className="col-span-3" key={curation.id}>
                            <HomeCurationItem title={`${curation.title}`} 
                            url={`/collections/${curation.id}`}
                            description={curation.description}
                            numberOfPlaces={curation.placeCount}
                            numberOfContributors={curation.memberCount}
                            imageUrl={curation.coverPhoto} 
                            emoji={curation.emoji}
                            />
                        </div>
                    })
                }
                {!loadingText && !isFirstLoading && (isShowingYourOwnCurationList ? userCurations : joinedCurations).length == 0 &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div>
                    <svg className="w-16 h-16 fill-base-content" version="1.1" viewBox="0 0 310.883 310.883">
                        <path d="M299.459,174.267c0,0-16.433-71.773-16.707-72.356c-3.429-10.694-17.078-19.279-40.725-25.565
                            c-23.243-6.181-53.993-9.584-86.586-9.584c-32.592,0-63.343,3.403-86.586,9.584c-23.657,6.29-37.308,14.879-40.729,25.58
                            c-0.272,0.578-16.702,72.342-16.702,72.342C4.778,176.576,0,182.879,0,190.312c0,18.79,17.893,33.075,53.18,42.458
                            c27.533,7.32,63.85,11.353,102.261,11.353c0.002,0,0.004,0,0.006,0c38.41,0,74.724-4.031,102.255-11.352
                            c35.287-9.383,53.18-23.668,53.18-42.459C310.883,182.879,306.105,176.576,299.459,174.267z M211.452,189.198
                            c0,7.987-6.498,14.486-14.485,14.486c-7.755,0-14.107-6.124-14.471-13.79h-54.11c-0.365,7.666-6.715,13.79-14.469,13.79
                            c-7.988,0-14.486-6.499-14.486-14.486c0-3.783,1.458-7.232,3.842-9.815c-2.384-2.583-3.842-6.032-3.842-9.815
                            c0-7.987,6.499-14.486,14.486-14.486c7.754,0,14.104,6.124,14.469,13.79h54.11c0.364-7.666,6.716-13.79,14.471-13.79
                            c7.987,0,14.485,6.499,14.485,14.486c0,3.783-1.458,7.232-3.842,9.815C209.994,181.966,211.452,185.415,211.452,189.198z
                            M235.357,120c-21.545,5.448-49.926,8.449-79.916,8.449c-29.99,0-58.371-3.001-79.916-8.449
                            c-20.722-5.24-28.012-10.998-29.796-13.382c1.8-2.425,9.104-8.177,29.8-13.409c21.544-5.448,49.924-8.448,79.912-8.448
                            c29.987,0,58.367,3,79.911,8.448c20.654,5.223,27.97,10.961,29.789,13.395C263.329,109.033,256.023,114.773,235.357,120z"/>
                        </svg>
                    </div>
                    <div className='font-normal text-base-content mt-2'>Nothing here.</div>
                    { isShowingYourOwnCurationList && <div className='font-normal text-base-content mt-2'>Press <span class="inline-flex items-baseline"><div className="btn btn-primary btn-sm mx-2 normal-case" onClick={() => {navigate("/collections/add")}}>+ Create Collection</div></span> to create a new one!</div> }
                </div>
                }
                {loadingText &&
                <div className='col-span-3 md:col-span-6 flex flex-col items-center justify-center pt-4'>
                    <div className="loading loading-infinity loading-lg"></div>
                    <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                </div>
                }
            </div>
        </div>
    )
}
