import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useAddCurationContext } from "./AddCurationContext";

export default function AddCurationSuccessPage({ analytics }) {

    const navigate = useNavigate()
    const {addCurationData, _} = useAddCurationContext()
    const { curationId }= useParams()

    useEffect(() => {
        logEvent(analytics, AnalyticsEvent.ADD_CURATION_SUCCESS_VIEW, {
            curation_id: curationId
        })
    
      }, [])

    function onBackPressed() {
        navigate("/")
    }
    return (
        <div>
            <Helmet><title>Created {addCurationData.curation.title}</title></Helmet>
            <CoorayAppBar title={`Created ${addCurationData.curation.title}`} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-lg text-base-content text-center pl-4 pr-4 pb-4">Good job! You have created a new collection: <span className="font-bold">{addCurationData.curation.title}</span></div>
                <a href={`/collections/${curationId}`} className=" px-4">
                    <button className="btn btn-active btn-primary w-full">View {addCurationData.curation.title}</button>
                </a>
            </div>
        </div>
    )
}
