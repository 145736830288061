import React, { createContext, useState, useContext } from 'react';

const CurationContext = createContext();

export const useCurationContext = () => {
  return useContext(CurationContext);
};

export const CurationProvider = ({ children }) => {
  const [curationData, setCurationData] = useState({
    curation: {
      id: 0,
      title: "",
      description: "",
    },
    filters: [],
  });

  return (
    <CurationContext.Provider value={{ curationData, setCurationData }}>
      {children}
    </CurationContext.Provider>
  );
};