import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createClient } from "@supabase/supabase-js"
import CoorayAppBar from "../../shared/components/CoorayAppBar"
import { NavigationButtonType } from "../../shared/components/CoorayAppBar"
import UserRepository from "../../repository/UserRepository"
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent"
import { logEvent } from "firebase/analytics"
import { Helmet } from "react-helmet"
import FeedbackButton from "../../shared/components/feedback/FeedbackButton"

const IMAGE_MAX_DIMENSION = 700
const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function EditProfilePage({ analytics }) {

    const [loadingText, setLoadingText] = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const [nameInput, setNameInput] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
  
        const userRepository = new UserRepository()
        async function getProfile() {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const response = await userRepository.getUserProfile()
              console.log(response)
              setNameInput(response.user.name)
              setUserProfile(response.user)
              setLoadingText(null)
            }
        }
        getProfile()
        logEvent(analytics, AnalyticsEvent.EDIT_PROFILE_VIEW, {})
      }, [])

    function onBackPressed() {
      navigate(-1)
    }

    function handleNameInputChange(event) {
      console.log(`name input change ${event.target.value}`)
      setNameInput(event.target.value)
    }

    async function onSaveClick() {
      setLoadingText("Saving ...")
      const userRepository = new  UserRepository()
      const updatedUserResponse = await userRepository.updateUserProfile(
          userProfile.handler, nameInput,
      )
      setUserProfile(updatedUserResponse.user)
      setLoadingText(null)
    }

    async function uploadImageToPlace(fileToBeUploaded) {
      // const imagefile = document.querySelector('#upload-image-input')
      // const fileToBeUploaded = imagefile.files[0]
      console.log(`image file ${fileToBeUploaded}`)
      setLoadingText("Uploading image ...")
      const userRepository = new  UserRepository()
      const updatedUserResponse = await userRepository.uploadProfilePicture(
          fileToBeUploaded
      )
      setUserProfile(
        {
          ...userProfile, profilePicture: updatedUserResponse.user.profilePicture
        }
      )
      setLoadingText(null)

  }

    function onFileInputChange(inputEvent) {
      // console.log(`on file picked ${event.target.files[0]}`)
      // setSelectedImage(event.target.files[0])
      // uploadImageToPlace()

      if (inputEvent.target.files) {
        let imageFile = inputEvent.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var img = document.createElement("img");
            img.onload = function (event) {
                var width = img.width;
                var height = img.height;

                // Change the resizing logic
                if (width > height) {
                    if (width > IMAGE_MAX_DIMENSION) {
                        height = height * (IMAGE_MAX_DIMENSION / width);
                        width = IMAGE_MAX_DIMENSION;
                    }
                } else {
                    if (height > IMAGE_MAX_DIMENSION) {
                        width = width * (IMAGE_MAX_DIMENSION / height);
                        height = IMAGE_MAX_DIMENSION;
                    }
                }

                var canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                  let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })
                  uploadImageToPlace(file)
                }, 'image/jpeg');

                // Show resized image in preview element
                // var dataurl = canvas.toDataURL(imageFile.type);
                // document.getElementById("preview").src = dataurl;
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(imageFile);
    }

  }

    return <div>
        <Helmet><title>Edit Profile</title></Helmet>
        <CoorayAppBar 
        title={ "" }
        onBackPressed={onBackPressed}
        navigationType={NavigationButtonType.Close}
        />
        {
          userProfile && <div className="px-4 gap-y-2 flex flex-col pt-4">
            <div className="form-control w-full max-w-md self-center">
                <label className="label">
                    <span className="label-text">Your display name</span>
                </label>
                <input type="text" placeholder="e.g. John Doe" className="input input-bordered w-full rounded-md" onChange={handleNameInputChange} value={nameInput} />
            </div>
            <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
              <button className="btn btn-success normal-case ml-4" onClick={() => onSaveClick()}>Save</button>
            </div>
            <div className="form-control w-full max-w-md self-center pt-2">
                <label className="label">
                    <span className="label-text">Profile picture</span>
                </label>
            </div>
            <div className="self-center max-w-md w-full">
            { !userProfile.profilePicture && <div className="avatar placeholder">
            <div className="bg-neutral text-xl text-neutral-content rounded-full w-24 border-4 border-base-content">
                <span>{ userProfile.name[0] }</span>
            </div>
            </div> 
            }
            { userProfile.profilePicture && <div className="avatar">
              <div className="w-24 border-4 border-base-content rounded-full">
                <img src={userProfile.profilePicture} />
              </div>
            </div>
            }
            </div>
            <input id="upload-image-input" type="file" accept="image/png, image/jpeg" className="file-input file-input-bordered w-full max-w-xs hidden" onChange={onFileInputChange}/>
            <div className='pt-2 max-w-md w-full self-center'>
              <button className="btn btn-primary normal-case" onClick={() => {
                document.getElementById('upload-image-input').click();
              }}>Upload Picture</button>
            </div>
          </div>
        }

        {loadingText &&
        <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText}</div>
        </div>
        }
        {
          <FeedbackButton />
        }
    </div>
}