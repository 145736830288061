import axios from "axios";
import { getGooglePlacesAutocompleteUrl, getGooglePlaceIdFromShareUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')


export default class GoogleRepository {
    async getPlacesAutocomplete(input) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getGooglePlacesAutocompleteUrl(input),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getPlaceIdFromShareUrl(shareUrl) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getGooglePlaceIdFromShareUrl(),
            {
                originalUrl: shareUrl
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }
}
