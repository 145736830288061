import Lottie from "lottie-react"
import lottieSuccess from '../../shared/lottie/lottie-success.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useNavigate } from "react-router-dom";

export default function InputCommentSuccessPage({placeData}) {
    const navigate = useNavigate()
    function onBackPressed() {
        navigate(`/places/${placeData.id}`, {replace: true})
    }
    return (
        <div>
            <CoorayAppBar title={"Added Succesfully"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-36 h-36" animationData={lottieSuccess} loop={true} />
                <div className="font-normal text-lg text-base-content text-center pl-4 pr-4">Good job! You have added a comment to <span className="font-bold">{placeData.title}</span></div>
            </div>
            <div className="w-full text-center px-4 pt-8">
                <a href={`/places/${placeData.id}`}>
                    <button className="btn btn-active btn-primary">View {placeData.title}</button>
                </a>
            </div>
        </div>
    )
}
