import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { useParams } from 'react-router-dom';
import CurationRepository from '../../repository/CurationRepository';
import { useNavigate } from 'react-router-dom';
import InputPlacePage from './InputPlacePage';
import PlaceRepository from '../../repository/PlaceRepository';
import AddPlaceSuccessPage from './AddPlaceSuccessPage';
import CoorayAppBar, { NavigationButtonType } from '../../shared/components/CoorayAppBar';
import CurationPicker from '../curationpicker/CurationPicker';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet';
import { useCurationContext } from '../curation/CurationContext';
import { useCurationPlacesContext } from '../curation/CurationPlacesContext';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function AddPlacePage({analytics}) {
  const [session, setSession] = useState(null)
  const [placeData, setPlaceData] = useState(null)
  const [addedPlaces, setAddedPlaces] = useState([])
  const [toastErrorMessage, setToastErrorMessage] = useState(null)
  const [pageData, setPageData] = useState(0)
  const [loadingText, setLoadingText] = useState(null)
  const {curationData, _} = useCurationContext()
  const { placesData, setPlacesData } = useCurationPlacesContext()
  const [addPlaceCurationData, setAddPlaceCurationData] = useState(null)
  const { curationId }= useParams()
  const placeRepository = new PlaceRepository()
  const navigate = useNavigate()

  useEffect(() => {
    if (curationData.curation.id == curationId) {
      console.log(`curation data ${JSON.stringify(curationData)}`)
      setAddPlaceCurationData(curationData)
      setPageData(1)
    } else {
      getCurations(curationId)
    }

    console.log("sending analytics")
    logEvent(analytics, AnalyticsEvent.ADD_PLACE_VIEW, {
      curation_id: curationId
    })

  }, [curationId])

  async function getCurations(curationId) {
    setLoadingText("Loading")
    const curationRepository = new CurationRepository()
    const {
      data: { user },
    } = await supabase.auth.getUser()
    if (user == null) {
      navigate("/login", {replace: true})
    } else {
      const curation = await curationRepository.getCurations(curationId)
      console.log(curation)
      setAddPlaceCurationData(curation)
      console.log(session)
      console.log("setting page data to 1")
      setLoadingText(null)
      setPageData(1)
    }
  }

  async function savePlace(currentPlaceData) {
    setLoadingText("Saving")
    const selectedFilterOptions = addPlaceCurationData.filters.flatMap((filter) => filter.filterOptions).filter((filterOption) => filterOption.isSelected == true)
    console.log(selectedFilterOptions)
    const savedPlace = await placeRepository.savePlace(
      addPlaceCurationData.curation.id,
      currentPlaceData.key,
      addPlaceCurationData.filters.flatMap((filter) => filter.filterOptions).filter((filterOption) => filterOption.isSelected == true).map((filterOption) => filterOption.id)
    )
    console.log(savedPlace)
    setAddedPlaces([savedPlace])
    const existingPlaces = placesData.places
    const selectedFilterOptionIds = savedPlace.filterOptions.map((option) => option.id)
    const processedFilters = []

    curationData.filters.forEach((filter) => {
      const relevantOptions = filter.filterOptions.filter((option) => selectedFilterOptionIds.includes(option.id))
      if (relevantOptions.length > 0) {
        processedFilters.push(
          {
            filter: filter,
            options: relevantOptions
          }
        )
      }
    })

    const processedAddedPlace = {
      ...savedPlace, filterOptions: processedFilters, filterOptionIds: selectedFilterOptionIds,
    }
    existingPlaces.unshift(processedAddedPlace)
    setPlacesData({
      ...placesData, places: existingPlaces
    })
    setLoadingText(null)
    setPageData(3)
  }

  async function saveExtractedPlaces(extractedPlaces) {
    setLoadingText("Saving")
    const selectedFilterOptions = addPlaceCurationData.filters.flatMap((filter) => filter.filterOptions).filter((filterOption) => filterOption.isSelected == true)
    console.log(selectedFilterOptions)
    const savedPlaces = await placeRepository.savePlaceWithExtractedPlaces(
      addPlaceCurationData.curation.id,
      extractedPlaces,
      addPlaceCurationData.filters.flatMap((filter) => filter.filterOptions).filter((filterOption) => filterOption.isSelected == true).map((filterOption) => filterOption.id)
    )

    console.log(savedPlaces)
    console.log(placesData)
    setAddedPlaces(savedPlaces.places.map((place) => {
      return {
        ...place, images: savedPlaces.imagesPerPlaceId[place.id]
      }
    }))

    const processedPlaces = savedPlaces.places.map((place) => {
      const selectedFilterOptionIds = place.filterOptionIds
      const processedFilters = []
      let images = []
      if (`${place.id}` in savedPlaces.imagesPerPlaceId) {
        images = savedPlaces.imagesPerPlaceId[`${place.id}`]
      }
  
      curationData.filters.forEach((filter) => {
        const relevantOptions = filter.filterOptions.filter((option) => selectedFilterOptionIds.includes(option.id))
        if (relevantOptions.length > 0) {
          processedFilters.push(
            {
              filter: filter,
              options: relevantOptions
            }
          )
        }
      })
      

      return {
        ...place, filterOptions: processedFilters, images: images
      }
    })
    // setAddedPlaceData(savedPlace)
    // const existingPlaces = placesData.places
    // const selectedFilterOptionIds = savedPlace.filterOptions.map((option) => option.id)
    // const processedFilters = []

    // curationData.filters.forEach((filter) => {
    //   const relevantOptions = filter.filterOptions.filter((option) => selectedFilterOptionIds.includes(option.id))
    //   if (relevantOptions.length > 0) {
    //     processedFilters.push(
    //       {
    //         filter: filter,
    //         options: relevantOptions
    //       }
    //     )
    //   }
    // })

    // const processedAddedPlace = {
    //   ...savedPlace, filterOptions: processedFilters, filterOptionIds: selectedFilterOptionIds,
    // }
    // existingPlaces.unshift(processedAddedPlace)
    setPlacesData({
      ...placesData, places: processedPlaces.concat(placesData.places)
    })
    setLoadingText(null)
    setPageData(3)
  }

  function onBackPressed() {
    navigate(`/collections/${curationId}`, { replace: true })
  }

  function onSaveClick(currentPlaceData, selectedExtractedPlaces) {
    if (currentPlaceData == null && selectedExtractedPlaces.length == 0) {
      setPageData(1)
      setToastErrorMessage("Please enter or select a place")
    } else if (selectedExtractedPlaces.length > 0 ) {
      console.log(`extracted ${selectedExtractedPlaces.length}`)
      saveExtractedPlaces(selectedExtractedPlaces)
    } else {
      savePlace(currentPlaceData)
    }
  }

  if (pageData == 0) {
    return (
      <div>
          <Helmet><title>{ addPlaceCurationData ? `Adding Place to ${addPlaceCurationData.title}` : "Add Place" }</title></Helmet>
          <CoorayAppBar title={"Add Place"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
          {loadingText &&
          <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText} ...</div>
          </div>
          }
      </div>
    )
  } else if(pageData == 1) {
    function onFilterOptionClick(clickedOption) {
      console.log(clickedOption)
      const filters = addPlaceCurationData.filters.map((filter) => {
        const options = filter.filterOptions.map((option) => {
          if (option.id == clickedOption.id) {
            const currentBool = option.isSelected == true
            option.isSelected = !currentBool
          }
          return option
        })
        filter.filterOptions = options
        return filter
      })
      const updatedaddPlaceCurationData = {
        filters: filters,
        curation: addPlaceCurationData.curation
      }
      setAddPlaceCurationData(updatedaddPlaceCurationData)
    }
    return (
        <div>
          <InputPlacePage 
            onBackPressed={onBackPressed}
            curationData={addPlaceCurationData} 
            placeData={placeData}
            onSaveClicked={
              (selectedPlace, selectedExtractedPlaces) => {
                setPlaceData(selectedPlace)
                // setPageData(2)
                onSaveClick(selectedPlace, selectedExtractedPlaces)
              }
            }
            onFilterOptionClick={onFilterOptionClick}
            onInputChange={(_) => {
              setToastErrorMessage(null)
            }}
            onSelectCurationTap={() => { console.log("on select tap");setPageData(2) }}
          />
          {toastErrorMessage &&
          <div className="toast toast-bottom toast-center">
            <div className="alert alert-info">
              <span>{toastErrorMessage}</span>
            </div>
          </div>
          }
          {loadingText &&
          <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
            <div className="loading loading-infinity loading-lg"></div>
            <div className='font-normal text-base-content mt-2'>{loadingText} ...</div>
          </div>
          }
        </div>
    )
  } else if (pageData == 2) {
    return <CurationPicker 
      shouldShowTitleAppBar={true}
      analytics={analytics}
      onPickerBackPressed={() => {}}
      onPickerCurationSelected={(curationId, _) => {
        setPageData(1)
        getCurations(curationId)
      }}
    />
  } else {
    return (
      <div>
        <AddPlaceSuccessPage 
          analytics={analytics}
          curationData={addPlaceCurationData} 
          addedPlaces={addedPlaces}
        />
      </div>
    ) 
  }
}