import React, { createContext, useState, useContext } from 'react';

const PlaceDetailsContext = createContext();

export const usePlaceDetailsContext = () => {
  return useContext(PlaceDetailsContext);
};

export const PlaceDetailsProvider = ({ children }) => {
  const [placeDetails, setPlaceDetails] = useState(null);

  return (
    <PlaceDetailsContext.Provider value={{ placeDetails, setPlaceDetails }}>
      {children}
    </PlaceDetailsContext.Provider>
  );
};